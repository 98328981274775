import React from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { Button } from "@/components/ui/button";
import {
  ArrowRight,
  Clock,
  HandCoins,
  Lock,
  Nfc,
  PercentCircle,
  WalletMinimal,
} from "lucide-react";
import { useNavigate } from "@tanstack/react-router";
import { Route as ShiftSummaryAvailableRoute } from "@/routes/reports/shift-history/shift-summary/$shiftId";
import { CSVLink } from "react-csv";
import { convertToCurrencyRounded } from "@/utils/currency_converter";
import dayjs from "dayjs";
import Details from "./components/details";
import Card from "./components/card";
import AutoEndShiftModal from "@/components/shifts/system-end-modal";

const Component = () => {
  const navigate = useNavigate();
  const searchParams = ShiftSummaryAvailableRoute.useSearch();
  const data = ShiftSummaryAvailableRoute.useLoaderData();

  const headers = [
    { label: "Gross Sales (Without VAT)", key: "gross_sales_amount" },
    { label: "Discounts", key: "total_discount" },
    { label: "VAT", key: "total_vat" },
    { label: "Net Sales (Including VAT)", key: "total_sales_amount" },
    { label: "Cash Payments", key: "cash_total" },
    { label: "Card Payments", key: "card_total" },
    { label: "Insurance Payments", key: "insurance_total" },
  ];

  const filename = "exported_data.csv";

  const formatDate = (dateString: string | number | Date) => {
    const date = new Date(dateString);
    const dayOfWeek = date.toLocaleString("en-US", { weekday: "long" });
    const month = date.toLocaleString("en-US", { month: "long" });
    const day = date.getDate();
    const year = date.getFullYear();
    return `${dayOfWeek} - ${month} ${day}, ${year}`;
  };

  console.log(searchParams);

  const total_gross =
    data.total_card_sales +
    data.total_cash_sales +
    data.total_insurance_sales -
    data.total_discount;

  const csvData = [
    {
      gross_sales_amount: convertToCurrencyRounded(total_gross),
      total_discount: convertToCurrencyRounded(data.total_discount),
      total_vat: convertToCurrencyRounded(data.total_vat),
      total_sales_amount: convertToCurrencyRounded(
        total_gross + data.total_vat
      ),
      cash_total: convertToCurrencyRounded(data.total_cash_sales),
      card_total: convertToCurrencyRounded(data.total_card_sales),
      insurance_total: convertToCurrencyRounded(data.total_insurance_sales),
    },
  ];

  const cashDrawerAmount =
    data.shift_details?.cashDrawerAmount?.startAmount ?? 0;
  const shiftName = data.shift_details?.shiftName ?? "Unknown";

  return (
    <div>
      <AutoEndShiftModal value={data.is_system_ended} setValue={() => {}} shiftID={data.shift_id} endTime={dayjs(data.end_time).format("hh:mm A")} />
      <div className="flex items-center justify-between">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink
                onClick={() => {
                  navigate({
                    to: "/invoicing",
                  });
                }}
                style={{
                  cursor: "pointer",
                }}
              >
                Reports Overview
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>Shift Summary</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
        <CSVLink data={csvData} headers={headers} filename={filename}>
          <Button>Export Shift Summary</Button>
        </CSVLink>
      </div>
      <hr className="my-4" />
      <div className="flex items-center justify-between">
        <p className="font-semibold text-3xl">
          <span className="text-black font-bold">
            {formatDate(dayjs(data.start_time).format("YYYY-MM-DD"))}
          </span>
        </p>
        <div className="flex items-center justify-between text-2xl font-bold text-gray-500 gap-x-3 ">
          <Clock className="h-6 w-6" />
          {dayjs(data.start_time).format(
            "HH:mm"
          )} <ArrowRight></ArrowRight> {dayjs(data.end_time).format("HH:mm")}
        </div>
      </div>
      <div className="grid grid-cols-4 gap-4 mt-4">
        <Card
          title="Starting cash"
          text={`${convertToCurrencyRounded(cashDrawerAmount)}`}
        />
        <Card title="Shift type" text={shiftName} />
        <Card title="Terminal ID" text="1" />
      </div>
      <p className="mt-8 text-gray-500 font-semibold">More details</p>
      <hr className="my-4" />
      <div className="grid grid-cols-6 gap-4 divide-x-2">
        <Details
          icon={<Lock />}
          title="Gross Sales (Without VAT)"
          text={`${convertToCurrencyRounded(total_gross)}`}
        />
        <Details
          icon={<PercentCircle />}
          title="Discounts"
          text={`${convertToCurrencyRounded(data.total_discount)}`}
        />
        <Details
          icon={<PercentCircle />}
          title="VAT"
          text={`${convertToCurrencyRounded(data.total_vat)}`}
        />
        <Details
          icon={<HandCoins />}
          title="Net Sales (Including VAT)"
          text={`${convertToCurrencyRounded(total_gross + data.total_vat)}`}
        />
        <Details
          icon={<WalletMinimal />}
          title="Cash Payments"
          text={`${convertToCurrencyRounded(data.total_cash_sales)}`}
        />
        <Details
          icon={<Nfc />}
          title="Card Payments"
          text={`${convertToCurrencyRounded(data.total_card_sales)}`}
        />
      </div>
    </div>
  );
};

export default Component;
