import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbPage, BreadcrumbSeparator } from '@/components/ui/breadcrumb'
import { useNavigate } from '@tanstack/react-router'
import { Button } from "@/components/ui/button"
import { ArrowUpRight, ChevronRight, Plus } from "lucide-react"
import { Checkbox } from "@/components/ui/checkbox"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table"
import { useState } from 'react'

const ReUse1 = ({
  label,
  value
}: {
  label: string,
  value: string
}) => {
  return (
    <div className='flex items-center justify-between text-sm mb-1'>
      <p className='font-light text-gray-500'>{label}</p>
      <p className='text-gray-600'>{value}</p>
    </div>
  )
}

const ReUse2 = ({
  label,
  value,
  link
}: {
  label: string,
  value: string,
  link: string
}) => {
  return (
    <div className='flex justify-between bg-[#ECEFF8] rounded-2xl p-4'>
      <div>
        <p className='text-sm text-gray-500'>{label}</p>
        <p className='text-xl mt-1'>{value}</p>
      </div>
      <p className='text-blue-600 text-sm font-light'>View</p>
    </div>
  )
}

const Component = () => {
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState('all')
  
  return (
    <>
      <div className="flex items-center justify-between">
        <Breadcrumb>
            <BreadcrumbList>
                <BreadcrumbItem>
                    <BreadcrumbLink onClick={() => {
                      navigate({
                        to: '/suppliers',
                      })
                    }} style={{
                      cursor: 'pointer'
                    }}>Suppliers</BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbSeparator />
                <BreadcrumbItem>
                    <BreadcrumbPage>"Supplier Name Here"</BreadcrumbPage>
                </BreadcrumbItem>
            </BreadcrumbList>
        </Breadcrumb>
      </div>
      <hr className="my-4" />

      <div className='grid grid-cols-4 gap-x-4'>

        {/* Left Half */}
        <div>
          <div className='bg-[#00000005] p-4 rounded-md'>
            <p className='text-3xl font-semibold'>Supplier Info</p>
            <p className='mt-6 font-semibold text-lg'>Supplier Details</p>
            <hr className='my-3'/>
            <ReUse1 label='Email' value='anandhu@neulink.cloud' />
            <ReUse1 label='Full Name' value='anandhu@neulink.cloud' />
            <ReUse1 label='Emirates ID' value='anandhu@neulink.cloud' />
            <hr className='mb-3' />
            <ReUse1 label='Location' value='anandhu@neulink.cloud' />
          </div>

          <div className='flex flex-col space-y-4 mt-6'>
            <ReUse2 label='Available Inventory' value='157' link='"' />
            <ReUse2 label='Available Inventory' value='157' link='"' />
            <ReUse2 label='Available Inventory' value='157' link='"' />
            <ReUse2 label='Available Inventory' value='157' link='"' />
          </div>
        </div>

        {/* Left Half */}
        <div className='col-span-3'>
          <p className='text-3xl font-semibold'>Outgoing payments</p>
          <p className='text-lg mt-6 text-gray-600 font-semibold'>Available to pay out this month</p>
          <hr className='my-3' />
          <div className='flex items-center justify-between'>
            <p className='text-sm text-gray-500'>Total</p>
            <p className='text-sm text-gray-500 '>AED 157</p>
          </div>
          <p className='mt-6 text-gray-500 font-semibold'>Still pending payouts to be made for this supplier</p>
          <hr className='mt-3 '/>
          <div className='flex items-center justify-between my-4'>
            <p className='text-blue-600'>Payout by March 10, 2025</p>
            <p className='text-sm text-gray-500'>AED 157</p>
          </div>
          {/* <hr />
          <p>View recent invoices</p> */}

          <div className='mt-8'>
            <div className='flex items-center justify-between mb-4'>
              <h2 className='text-3xl font-semibold'>Invoices</h2>
              <div className='flex gap-2'>
                <Button size="sm" variant="outline" className="flex gap-2 shadow-sm">
                  <ArrowUpRight className="h-6 w-6" />
                  Export All
                </Button>
                <Button size="sm" className="flex gap-2 bg-blue-600">
                  <Plus className="h-6 w-6" />
                  Repeat Invoice
                </Button>
              </div>
            </div>

            <div className="flex gap-3 border-b mt-6 mb-2">
              <button 
                className={`pb-2 px-2 text-sm font-medium ${
                  activeTab === 'all' 
                    ? 'text-blue-600 border-b-2 border-blue-600' 
                    : 'text-gray-500'
                }`}
                onClick={() => setActiveTab('all')}
              >
                All
              </button>
              <button 
                className={`pb-2 px-2 text-sm font-medium ${
                  activeTab === 'succeeded' 
                    ? 'text-blue-600 border-b-2 border-blue-600' 
                    : 'text-gray-500'
                }`}
                onClick={() => setActiveTab('succeeded')}
              >
                Succeeded
              </button>
              <button 
                className={`pb-2 px-2 text-sm font-medium ${
                  activeTab === 'refunded' 
                    ? 'text-blue-600 border-b-2 border-blue-600' 
                    : 'text-gray-500'
                }`}
                onClick={() => setActiveTab('refunded')}
              >
                Refunded
              </button>
            </div>

            <Table>
              <TableHeader>
                <TableRow className='text-sm'>
                  <TableHead className="w-[50px]"><Checkbox /></TableHead>
                  <TableHead>AMOUNT</TableHead>
                  <TableHead>DATE</TableHead>
                  <TableHead>INVOICE NO.</TableHead>
                  <TableHead>UNIQUE SKU'S</TableHead>
                  <TableHead></TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                <TableRow>
                  <TableCell><Checkbox /></TableCell>
                  <TableCell>
                    <div className="flex items-center gap-2">
                      <span><span className='text-gray-500'>AED</span> 4,242.00</span>
                      <span className="text-xs px-2 py-1 rounded-full bg-green-100 text-green-800">Succeeded</span>
                    </div>
                  </TableCell>
                  <TableCell>May 31, 2020, 11:58 AM</TableCell>
                  <TableCell>Invoice 9CER2A3</TableCell>
                  <TableCell>12</TableCell>
                  <TableCell>
                  <Button variant="outline" size="sm" className="px-2 flex items-center gap-1 text-sm">
                    <ChevronRight className="h-4 w-4" />
                    More details  
                    </Button>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><Checkbox /></TableCell>
                  <TableCell>
                    <div className="flex items-center gap-2">
                      <span><span className='text-gray-500'>AED</span> 4,242.00</span>
                      <span className="text-xs px-2 py-1 rounded-full bg-yellow-100 text-yellow-800">Yellow</span>
                    </div>
                  </TableCell>
                  <TableCell>May 31, 2020, 11:58 AM</TableCell>
                  <TableCell>Invoice 9CER2A3</TableCell>
                  <TableCell>12</TableCell>
                  <TableCell>
                    <Button 
                      variant="outline" 
                      size="sm" 
                      className="px-2 flex items-center gap-1 text-sm"
                      onClick={() => navigate({
                        to: '/suppliers/$supplierId/$invoiceId',
                        params: {
                          invoiceId: 'your-invoice-id'
                        }
                      })}
                    >
                      <ChevronRight className="h-4 w-4" />
                      More details                      
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            
          </div>
        </div>

      </div>

    </>
  )
}

export default Component