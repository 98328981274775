// import { API_URL, API_URL_SUFFIX } from "@/constants/api";
// import { extractProps } from "@/utils/api";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { Supplier } from "@/screens/suppliers/components/data/schema";
import { z } from "zod";
import { Loading } from "@/layouts/loading";
import { PurchaseOrderSingleType } from "@/screens/reports/purchase-order-list/type";
import Stepper from "@/components/multipage-stepper";
import React, { useEffect, useMemo } from "react";
import ReviewTable from "@/screens/draft-purchase-invoice/components/reviewtable";
import ReviewColumn from "@/screens/draft-purchase-invoice/components/reviewcolumn";
import { submitPurchaseInvoice } from "@/screens/draft-purchase-invoice/components/data/api";
import { toast } from "@/components/ui/use-toast";
import PurchaseInvoiceForm from "@/screens/draft-purchase-invoice/components/purchaseInvoiceForm";
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from "@/components/ui/resizable";
import TableList from "@/screens/inventory/add-stock/components/table-list";
import Add, { addedItemsValidator, checkDuplicateBatch, formValidator } from "@/screens/inventory/add-stock/components/add";
import InvoicePDF, { PurchaseInvoicePDFItemSchema } from "@/screens/reports/purchase-invoice-list/components/invoice-pdf";
import { pdf } from "@react-pdf/renderer";
import { httpClient } from "@/httpClient";
import getPharmacyID from "@/utils/pharmacy_code";
import { formObjToAddedItems } from "@/screens/inventory/add-stock/component";
import dayjs from "dayjs";
import { AddedItemsSchema } from "@/screens/inventory/add-stock/schema";
import { PurchaseInvoiceSchema } from "@/screens/draft-purchase-invoice/components/data/schema";
import { dateToDB } from "@/utils/time_format";
import { AddStockItemRow, addAddStockParams, addAddStockItem, deleteAddStockItem, updateAddStockItem } from "@/utils/db/addStock";
import { completeCreatePIInstance, completeDraftPI, PIInfoRow, setDraftPIInfo, setPIInfo } from "@/utils/db/purchaseInvoice";
import { purchaseInvoiceSchemaValidator } from "../create-purchase-invoice";
import { Info } from "luxon";
import db from "@/db";

const DraftPurchaseInvoiceParams = z.object({
    POId: z.string()
})
type PIDraftInfo =
    Pick<PurchaseOrderSingleType, "supplier_info">
    & {
        items: AddedItemsSchema[],
        id: string
    }

export const Route = createFileRoute("/draft-purchase-invoice/")({
    component: Component,
    validateSearch: DraftPurchaseInvoiceParams,
    loaderDeps: ({ search: { POId } }) => ({
        POId
    }),
    loader: async (params): Promise<{
        PIDraft: PIDraftInfo,
        suppliers: Supplier[]
    }> => {
        let POData = undefined as PIDraftInfo | undefined
        const POId = params.deps.POId

        const suppliersRes = httpClient.post(`${import.meta.env.VITE_API_URL as string}/public/pharmacy/supplier/get/list?offset=0&limit=100000`, {
            pharmacy_code: getPharmacyID()
        });
        const PODataRes = httpClient.post(`${import.meta.env.VITE_API_URL as string}/public/pharmacy/purchase_order/get/one`, {
            purchase_order_id: POId
        });
        const [suppliersData, PODataResData] = await Promise.all([suppliersRes, PODataRes])

        const suppliers = suppliersData.data.data as Supplier[]

        const res = PODataResData.data.data as PurchaseOrderSingleType
        let PIitems: AddedItemsSchema[] = res.items.map((item => {
            return {
                batch_number: "",
                cost: item.intended_cost,
                expiry_date: undefined,
                production_date: undefined,
                db_item_id: -1,
                medical_master_id: item.type === "MEDICAL" ? item.id : undefined,
                non_medical_master_id: item.type === "NON_MEDICAL" ? item.id : undefined,
                price: undefined,
                pharmacy_code: getPharmacyID(),
                quantity: {
                    bonus: item.bonus_quantity,
                    original: item.quantity
                },
                trade_name: item.trade_name,
                type: item.type as "MEDICAL" | "NON_MEDICAL",
                vat: item.vat.toString(),
                metadata: {
                    custom_barcode: item.barcode,
                    discount_type: "NONE",
                    discount_value: 0
                },
            }
        }))
        POData = {
            id: POId,
            items: PIitems,
            supplier_info: res.supplier_info
        }
        return {
            PIDraft: POData,
            suppliers: suppliers
        };

    },
    pendingComponent: () => <Loading />,
    pendingMinMs: 1000,
    pendingMs: 1,
});



function Component() {
    const { PIDraft } = Route.useLoaderData()

    const [purchaseInvoiceData, setPurchaseInvoiceData] = React.useState<PurchaseInvoiceSchema>({
        instance_id: -1,
        delivery_date: undefined,
        invoice_date: undefined,
        invoice_number: "",
        payment_mode: undefined,
        payment_type: undefined,
        supplier_id: PIDraft.supplier_info.id,
        po_id: PIDraft.id
    })

    const [activeStep, setActiveStep] = React.useState(0)
    const [globalItems, setGlobalItems] = React.useState<AddedItemsSchema[]>(PIDraft.items)

    const navigate = useNavigate()
    const [supplierName, setSupplierName] = React.useState("")
    const [editItem, setEditItem] = React.useState<AddedItemsSchema>()
    const [allItemsSet, setAllItemsSet] = React.useState(false)

    const isItemsValid = useMemo(() => {
        return addedItemsValidator(globalItems)
    }, [globalItems])

    const isInvoiceValid = useMemo(() => {
        return purchaseInvoiceSchemaValidator(purchaseInvoiceData)
    }, [purchaseInvoiceData])
    async function handleSubmit() {
        if (purchaseInvoiceData.instance_id === undefined) {
            toast({
                title: "Error",
                description: "Please fill in the required fields",
                duration: 5000,
            })
            return
        }
        if (purchaseInvoiceData === undefined) {
            toast({
                title: "Error",
                description: "Please fill in the required fields",
                duration: 5000,
            })
            return
        }
        if (purchaseInvoiceData.delivery_date === undefined || purchaseInvoiceData.invoice_date === undefined || purchaseInvoiceData.invoice_number === undefined || purchaseInvoiceData.payment_mode === undefined || purchaseInvoiceData.payment_type === undefined) {
            toast({
                title: "Error",
                description: "Please fill in the required fields",
                duration: 5000,
            })
            return
        }
        if (globalItems.length === 0) {
            toast({
                title: "Error",
                description: "Please add at least one item",
                duration: 5000,
            })
            return
        }
        try {
            const res = await submitPurchaseInvoice(globalItems, purchaseInvoiceData)
            if (!res) {
                toast({
                    title: "Error",
                    description: "An error occurred while submitting the purchase invoice",
                    duration: 5000,
                })
                return;
            } else {
                const apiUrl = import.meta.env.VITE_API_URL as string;
                try {
                    const pharmacyData = await httpClient.post(`${apiUrl}/public/pharmacy`, {
                        pharmacy_code: getPharmacyID()
                    })
                    const medicalPDFItems: PurchaseInvoicePDFItemSchema[] = []
                    const nonMedicalPDFItems: PurchaseInvoicePDFItemSchema[] = []
                    for (let item of globalItems) {
                        if (item.type === "MEDICAL") {
                            medicalPDFItems.push({
                                trade_name: item.trade_name,
                                cost: item.cost,
                                quantity: item.quantity.original + item.quantity.bonus,
                                batch_number: item.batch_number,
                                vat: !isNaN(Number(item.vat)) ? Number(item.vat) : 0
                            })
                        } else if (item.type === "NON_MEDICAL") {
                            nonMedicalPDFItems.push({
                                trade_name: item.trade_name,
                                cost: item.cost,
                                quantity: item.quantity.original + item.quantity.bonus,
                                batch_number: item.batch_number,
                                vat: !isNaN(Number(item.vat)) ? Number(item.vat) : 0
                            })
                        }
                    }
                    const pdfDoc = <InvoicePDF
                        invoiceDetails={{
                            calculated_payment_date: undefined,
                            delivery_date: purchaseInvoiceData.delivery_date.toDateString(),
                            supplier_name: supplierName,
                            payment_mode: purchaseInvoiceData.payment_mode,
                            payment_type: purchaseInvoiceData.payment_type,
                            invoice_number: purchaseInvoiceData.invoice_number,

                        }}
                        medicalItems={medicalPDFItems}
                        nonMedicalItems={nonMedicalPDFItems}
                        pharmacyName={pharmacyData.data.data.metadata.pharmacy_name}
                    >
                    </InvoicePDF>
                    pdf(pdfDoc).toBlob().then((blob) => {
                        const url = URL.createObjectURL(blob)
                        window.open(url, '_blank')
                    })
                    completeDraftPI(purchaseInvoiceData.instance_id, () => {
                    }, () => { })
                    toast({
                        title: "Purchase Invoice Submitted",
                        description: "Purchase Invoice has been submitted successfully",
                        duration: 5000,
                    })
                    navigate({
                        to: "/reports/purchase-invoice-list",
                    })
                } catch (e) {
                    toast({
                        title: "Couldn't generate PDF",
                        description: "An error occurred while generating the PDF",
                        duration: 5000,
                    })
                    console.log(e)
                }
            }
        } catch (error) {
            console.log(error)
            toast({
                title: "Error",
                description: "An error occurred while submitting the purchase invoice",
                duration: 5000,
            })
        }
    }
    const deleteItem = (item: AddedItemsSchema) => {
        if (item.db_item_id === -1) {
            setGlobalItems(globalItems.filter((i) => {
                if (i.type === "MEDICAL") {
                    return i.medical_master_id !== item.medical_master_id
                } else {
                    return i.non_medical_master_id !== item.non_medical_master_id
                }
            }))
        } else {
            deleteAddStockItem(item.db_item_id, () => {
                setGlobalItems(globalItems.filter((i) => {
                    if (i.type === "MEDICAL") {
                        return i.medical_master_id !== item.medical_master_id
                    } else {
                        return i.non_medical_master_id !== item.non_medical_master_id
                    }
                }))
            }, (e) => {
                console.log(e)
                toast({
                    title: "Error",
                    description: "Failed to delete item. Please try again later. If the problem persists, contact support.",
                    duration: 5000,
                })
            })
        }
    }


    useEffect(() => {
        db.transaction('rw', db.draft_pi_instance, async (tx) => {
            let instance_id = -1;
            let info: PurchaseInvoiceSchema = {}
            let items: AddedItemsSchema[] = PIDraft.items
            let supplier_id = PIDraft.supplier_info.id
            // const infoRes = await tx.query<PIInfoRow>(`SELECT info.* FROM draft_pi_instance instance join create_pi_info info on info.instance_id = instance.id WHERE instance.po_id = $1`, [PIDraft.id])
            const infoRes = await tx.draft_pi_instance.where({ po_id: PIDraft.id }).first()
            const createPiInfo = await tx.create_pi_info.where({ instance_id: infoRes?.id }).first()
            if (!infoRes) {
                info.db_id = -1;
                info.instance_id = -1;
                info.supplier_id = supplier_id;
            } else {
                let selectedInfo = createPiInfo!
                info = {
                    db_id: selectedInfo.id,
                    instance_id: selectedInfo.instance_id,
                    supplier_id: selectedInfo.supplier_id,
                    invoice_number: selectedInfo.invoice_number,
                    invoice_date: selectedInfo.invoice_date,
                    delivery_date: selectedInfo.delivery_date,
                    payment_mode: (selectedInfo.payment_mode || undefined) as "CASH" | "CHEQUE" | "CREDIT_CARD" | "BANK_TRANSFER" | undefined,
                    payment_type: (selectedInfo.payment_type || undefined) as "CREDIT" | "INSTANT" | undefined
                }
                // const itemsRes = await tx.query<AddStockItemRow>(`SELECT item.* FROM add_stock_item item JOIN draft_pi_instance instance ON item.instance_id = instance.id WHERE instance.po_id = $1 AND item.instance_type='DRAFT_PI'`, [PIDraft.id])
                const itemsRes = await tx.add_stock_item.where({ instance_id: info.instance_id }).toArray()

                if (itemsRes.length === 0) {
                    items = PIDraft.items
                } else {
                    instance_id = itemsRes[0].instance_id
                    setAllItemsSet(true)
                    items = itemsRes.map(StockItemRowToAddeditem)
                }
            }
            setPurchaseInvoiceData(info)
            setGlobalItems(items)
        }).then(() => {
        })
    }, [])

    return (
        <>


                <div className="flex flex-col h-screen">
                <div className="flex-1 overflow-auto">
                <div
                    className="grid grid-cols-8 gap-4 p-4 mb-32"
                >
                    {activeStep === 0 &&
                        <div className="col-span-8 flex flex-col">
                            <div>
                                <h2 className="text-2xl font-bold tracking-tight">Purchase Invoice</h2>
                                <p className="text-muted-foreground">
                                    The following details are required to create a new invoice from the purchase order.
                                </p>
                            </div>
                            <hr className="my-6" />
                            <PurchaseInvoiceForm data={purchaseInvoiceData} setData={async (info) => {
                                let newInstanceId = info.instance_id
                                if (newInstanceId === -1) {
                                    console.log("Creating new instance")
                                    const newInstanceRes = await db.draft_pi_instance.add({ complete: false, updated_at: new Date(), po_id: PIDraft.id })
                                    newInstanceId = newInstanceRes
                                    const newInfoRes = await db.create_pi_info.add({
                                        instance_id: newInstanceId,
                                        invoice_number: info.invoice_number!,
                                        invoice_date: info.invoice_date!,
                                        delivery_date: info.delivery_date!,
                                        payment_mode: info.payment_mode!,
                                        payment_type: info.payment_type!,
                                        supplier_id: info.supplier_id!,
                                        instance_type: "DRAFT_PI",
                                        po_id: PIDraft.id
                                    })
                                    info.instance_id = newInstanceId
                                    info.db_id = newInfoRes
                                    setPurchaseInvoiceData(info)
                                } else {
                                    setDraftPIInfo(info, PIDraft.id, () => {
                                        setPurchaseInvoiceData(info)
                                    }, (e) => {
                                        console.log(e)
                                    })
                                }
                            }} supplierName={supplierName} setSupplierName={setSupplierName} />
                        </div>
                    }
                    {
                        activeStep === 1 &&
                        <>
                            <ResizablePanelGroup className="col-span-8 flex" direction={"horizontal"}>
                                <ResizablePanel className="flex-1 mr-2">
                                    <TableList
                                        items={globalItems}
                                        deleteItem={deleteItem}
                                        setEditItem={setEditItem}
                                    />
                                </ResizablePanel>
                                <ResizableHandle withHandle />
                                <ResizablePanel
                                    minSize={20}
                                    maxSize={40}
                                    defaultSize={20}
                                    className="bg-[#F6F6F6] overflow-auto">
                                    <Add key={globalItems.length}
                                        injectItem={editItem}
                                        resetInjectItem={() => setEditItem(undefined)}
                                        addItem={async (formObjItem) => {
                                            let copied_instanceId = purchaseInvoiceData.instance_id
                                            if (copied_instanceId === -1) {
                                                const newInstanceRes = await db.draft_pi_instance.add({ complete: false, updated_at: new Date(), po_id: PIDraft.id })
                                                copied_instanceId = newInstanceRes
                                            }
                                            setPurchaseInvoiceData({ ...purchaseInvoiceData, instance_id: copied_instanceId })
                                            const newItems = [...globalItems]
                                            newItems.forEach((item) => {
                                                if (item.db_item_id == -1) {
                                                    const param: addAddStockParams = {
                                                        instanceId: copied_instanceId!,
                                                        product_name: item.trade_name,
                                                        batch_no: item.batch_number,
                                                        cost: item.cost,
                                                        bonus: item.quantity.bonus,
                                                        custom_barcode: item.metadata.custom_barcode,
                                                        discount_type: item.metadata.discount_type,
                                                        discount_value: item.metadata.discount_value,
                                                        expiry_date: item.expiry_date,
                                                        price: item.price,
                                                        production_date: item.production_date,
                                                        quantity: item.quantity.original,
                                                        type: item.type,
                                                        vat: Number(item.vat),
                                                        item_id: item.type === "MEDICAL" ? item.medical_master_id : item.non_medical_master_id,
                                                    }
                                                    addAddStockItem(param, "DRAFT_PI", (new_id) => {
                                                        //mutation should work
                                                        item.db_item_id = new_id!
                                                    })
                                                }
                                            })
                                            addAddStockItem({
                                                product_name: formObjItem.trade_name,
                                                batch_no: formObjItem.batchNo,
                                                cost: formObjItem.cost.value,
                                                bonus: formObjItem.bonus.value,
                                                custom_barcode: formObjItem.customBarcode,
                                                discount_type: formObjItem.discountType,
                                                discount_value: formObjItem.discountValue.value,
                                                expiry_date: formObjItem.expiryDate ? dayjs(formObjItem.expiryDate).format("YYYY-MM-DD") : dayjs().add(1, "year").format("YYYY-MM-DD"),
                                                price: formObjItem.price.value || 0,
                                                production_date: dayjs(formObjItem.productionDate!).format("YYYY-MM-DD"),
                                                quantity: formObjItem.quantity.value,
                                                type: formObjItem.type,
                                                vat: formObjItem.vat,
                                                instanceId: Number(copied_instanceId),
                                                item_id: formObjItem.item_id,
                                            },
                                                "DRAFT_PI",
                                                (v) => {
                                                    console.log("new id  = ", v)
                                                    const newItem = formObjToAddedItems(formObjItem, v!)
                                                    setGlobalItems(newItems.concat(newItem))
                                                },
                                                () => {
                                                    toast({
                                                        title: "Error",
                                                        description: "Failed to add item. Please try again later. If the problem persists, contact support.",
                                                        duration: 5000,
                                                    })
                                                }
                                            )
                                        }}
                                        editItem={async (formObjItem, dbItemId) => {
                                            const newItem = formObjToAddedItems(formObjItem, dbItemId)
                                            let copied_instanceId = purchaseInvoiceData.instance_id
                                            if (copied_instanceId === -1) {
                                                const newInstanceRes = await db.draft_pi_instance.add({ complete: false, updated_at: new Date(), po_id: PIDraft.id })
                                                copied_instanceId = newInstanceRes
                                            }
                                            setPurchaseInvoiceData({ ...purchaseInvoiceData, instance_id: copied_instanceId })
                                            //delete old items add a new items
                                            globalItems.forEach((item) => {
                                                if (item.db_item_id != -1) {
                                                    deleteAddStockItem(item.db_item_id, () => {
                                                        console.log("Deleted item")
                                                    }, (e) => {
                                                        console.log(e)
                                                        toast({
                                                            title: "Error",
                                                            description: "Failed to delete item. Please try again later. If the problem persists, contact support.",
                                                            duration: 5000,
                                                        })
                                                    })
                                                }
                                            })

                                            const newItems = globalItems.map((item) => {
                                                if (item.type === "MEDICAL" && item.medical_master_id === newItem.medical_master_id) {
                                                    return newItem
                                                }
                                                if (item.type === "NON_MEDICAL" && item.non_medical_master_id === newItem.non_medical_master_id) {
                                                    return newItem
                                                }
                                                return item
                                            })

                                            newItems.forEach((item) => {
                                                const param: addAddStockParams = {
                                                    instanceId: copied_instanceId!,
                                                    product_name: item.trade_name,
                                                    batch_no: item.batch_number,
                                                    cost: item.cost,
                                                    bonus: item.quantity.bonus,
                                                    custom_barcode: item.metadata.custom_barcode,
                                                    discount_type: item.metadata.discount_type,
                                                    discount_value: item.metadata.discount_value,
                                                    expiry_date: item.expiry_date!,
                                                    price: item.price,
                                                    production_date: item.production_date!,
                                                    quantity: item.quantity.original,
                                                    type: item.type,
                                                    vat: Number(item.vat),
                                                    item_id: item.type === "MEDICAL" ? item.medical_master_id : item.non_medical_master_id,
                                                }
                                                addAddStockItem(param, "DRAFT_PI", (new_id) => {
                                                    item.db_item_id = new_id!
                                                })
                                            })

                                            setGlobalItems(newItems)
                                        }}

                                        items={globalItems} />
                                </ResizablePanel>
                            </ResizablePanelGroup>
                        </>
                    }
                    {
                        activeStep === 2 &&
                        <>
                            <div className="col-span-6 overflow-auto">
                                <ReviewTable globalItems={globalItems} purchaseInvoiceData={purchaseInvoiceData} supplierName={supplierName} deleteItem={deleteItem} editItem={(item) => {
                                    setEditItem(item)
                                    setActiveStep(1)
                                }} />
                            </div>
                            <div className="col-span-2 bg-[#F5F6F7] min-h-full p-4">
                                <ReviewColumn globalItems={globalItems} />
                            </div>
                        </>
                    }
                </div>
                </div>
            </div>
            <Stepper
                stepValues={[
                    {
                        name: 'Invoice Info',
                        next: () => { },
                        validate: () => isInvoiceValid
                    },
                    {
                        name: 'Add Items',
                        next: () => { },
                        validate: () => isItemsValid,
                    },
                    {
                        name: 'Review',
                        next: handleSubmit,
                        validate: () => {
                            return isInvoiceValid && isItemsValid
                        }
                    },
                ]}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
            />

        </>
    )
}



function StockItemRowToAddeditem(item: AddStockItemRow): AddedItemsSchema {
    return {
        batch_number: item.batch_no,
        cost: Number(item.cost),
        expiry_date: item.expiry_date!,
        production_date: item.production_date!,
        quantity: {
            bonus: item.bonus,
            original: item.quantity
        },
        type: item.type as "MEDICAL" | "NON_MEDICAL",
        metadata: {
            custom_barcode: item.custom_barcode,
            discount_type: item.discount_type as "NONE" | "PERCENTAGE" | "VALUE" | undefined,
            discount_value: Number(item.discount_value)
        },
        db_item_id: item.id,
        medical_master_id: item.type === "MEDICAL" ? item.item_id : undefined,
        non_medical_master_id: item.type === "NON_MEDICAL" ? item.item_id : undefined,
        price: Number(item.price),
        pharmacy_code: getPharmacyID(),
        trade_name: item.product_name,
        vat: item.vat.toString()
    }
}