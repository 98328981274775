import React from 'react'
import { Button } from '@/components/ui/button'
import { X, ChevronUp, ChevronDown, DownloadIcon, SendToBack, ActivityIcon, ReceiptText, IdCard, AtSign, CalendarDays, CalendarCheck, FileText, CreditCard } from 'lucide-react'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table"

const ReUse1 = ({
    icon,
    name
}: {
    icon: React.ReactNode,
    name: string
}) => {
    return (
        <div className='flex items-center gap-x-2 text-sm text-gray-500'>
            {icon}
            <span>{name}</span>
        </div>
    )
}

const Component = () => {
  return (
    <div className="p-4 border rounded-xl min-h-screen">
      <div className="flex items-center gap-2">
        <Button variant="ghost" size="icon">
          <X className="h-5 w-5" />
        </Button>
        <div className="flex items-center gap-2">
          <Button variant="outline" size="icon">
            <ChevronUp className="h-5 w-5" />
          </Button>
          <Button variant="outline" size="icon">
            <ChevronDown className="h-5 w-5" />
          </Button>
        </div>
        <span className="text-sm text-gray-600">1 of 24 invoices</span>
      </div>

      <hr className='my-4' />

      <div className='flex items-center justify-between'>
        <p className='text-2xl font-semibold'>
            Al Khaleej Drugstore
        </p>
        <div className='flex items-center gap-2'>
            <Button size="sm" variant="outline" className="flex gap-2 shadow-sm">
                <SendToBack className='w-4 h-4' />
                Reorder
            </Button>
            <Button size="sm" variant="outline" className="flex gap-2 shadow-sm">
                <DownloadIcon className='w-4 h-4' />
                Export as pdf
            </Button>
        </div>
      </div>

      <div className='grid grid-cols-5 flex-1 h-full border divide-x mt-4'>
        <div className='min-h-[500px] col-span-3 py-3'>
            <div className='font-normal flex items-center gap-2 border rounded-xl py-1 px-2 w-min mx-4'>
                <ActivityIcon className='w-4 h-4' />
                Items
            </div>
            <hr className='mt-3' />

            <div className="border-b">
                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableHead className='text-black font-normal'>Product Name</TableHead>
                            <TableHead className='text-black font-normal border-x'>Total Quantity</TableHead>
                            <TableHead className='text-black font-normal border-x'>VAT</TableHead>
                            <TableHead className='text-black font-normal border-x'>Net Total</TableHead>
                            <TableHead className='text-black font-normal'>Total</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        <TableRow>
                            <TableCell>Midotab</TableCell>
                            <TableCell className="bg-gray-200/30 text-gray-400 border-x">24</TableCell>
                            <TableCell className="bg-gray-200/30 text-gray-400 border-x">0</TableCell>
                            <TableCell className="bg-gray-200/30 text-gray-400 border-x">AED 97</TableCell>
                            <TableCell className="bg-gray-200/30 text-gray-400">AED 127.00</TableCell>
                        </TableRow>
                        <TableRow className='divide-x'>
                            <TableCell>Panadol</TableCell>
                            <TableCell className="bg-gray-200/30 text-gray-400">21</TableCell>
                            <TableCell className="bg-gray-200/30 text-gray-400">0</TableCell>
                            <TableCell className="bg-gray-200/30 text-gray-400">AED 90</TableCell>
                            <TableCell className="bg-gray-200/30 text-gray-400">AED 900.00</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <div className='grid grid-cols-4 divide-x-2 px-4 border-t'>
                    <p className='py-2 text-right px-4'>6 count</p>
                    <p className='text-center py-2'>0</p>
                    <p className='py-2 text-right px-4'>AED 400.00</p>
                    <p className='py-2 text-right px-4'>AED 3000.00</p>
                </div>
            </div>
        </div>

        <div className='min-h-[500px] col-span-2 py-3'>
            <div className='font-normal flex items-center gap-2 border rounded-xl py-1 px-2 w-min mx-4'>
                <ReceiptText className='w-4 h-4' />
                Details
            </div>
            <hr className='my-3' />

            <div className='grid grid-cols-2 gap-4 px-4 max-w-[500px]'>
                <ReUse1 icon={<IdCard className='w-6 h-6' />} name="Name" />
                <p>Robin</p>
                <ReUse1 icon={<AtSign className='w-5 h-5' />} name="Email Address" />
                <span className='border-2 w-min rounded-xl text-sm border-blue-200 text-blue-500 px-3 py-1'>vishal@alkej.ae</span>
                <ReUse1 icon={<CalendarDays className='w-5 h-5' />} name="Invoice Date" />
                <p className='text-gray-500'>03 March 2025</p>
                <ReUse1 icon={<CalendarCheck className='w-5 h-5' />} name="Delivery Date" />
                <p className='text-gray-500'>02 March 2025</p>
                <ReUse1 icon={<FileText className='w-5 h-5' />} name="Invoice Number" />
                <p className='text-gray-500'>RJ189282010</p>
                <ReUse1 icon={<CreditCard className='w-5 h-5' />} name="Payment Type" />
                <p className='text-gray-500'>Card</p>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Component