import { httpClient } from "@/httpClient";
import { MedicalItemSchema, NonMedicalItemSchema, PurchaseInvoiceSchema } from "./schema";
import getPharmacyID from "@/utils/pharmacy_code";
import { z } from "zod";
import dayjs from "dayjs";
import { AddedItemsSchema } from "@/screens/inventory/add-stock/schema";

const MedicalItemAddSchema = z.object({
    batch_number: z.string(),
    cost: z.number(),
    expiry_date: z.string(),
    pharmacy_code: z.string(),
    production_date: z.string(),
    quantity: z.object({
        bonus: z.number(),
        original: z.number(),
    }),
    medical_master_id: z.string(),
    metadata: z.object({
        custom_barcode: z.string(),
        maximum_discount_type: z.enum(["NONE", "PERCENTAGE", "VALUE", "ALWAYS"]),
        maximum_discount_value: z.number(),
    })
})

const NonMedicalItemAddSchema = z.object({
    batch_number: z.string(),
    cost: z.number(),
    expiry_date: z.string(),
    price: z.number().optional(),
    production_date: z.string(),
    quantity: z.object({
        bonus: z.number(),
        original: z.number(),
    }),
    non_medical_master_id: z.string(),
    metadata: z.object({
        custom_barcode: z.string(),
        maximum_discount_type: z.enum(["NONE", "PERCENTAGE", "VALUE", "ALWAYS"]),
        maximum_discount_value: z.number(),
    })
})

type MedicalItemAddSchema = z.infer<typeof MedicalItemAddSchema>
type NonMedicalItemAddSchema = z.infer<typeof NonMedicalItemAddSchema>

export const getMedicalItemInfo = async (medicalMasterId: string): Promise<MedicalItemSchema> => {
    const response = await httpClient.get(`${import.meta.env.VITE_API_URL as string}/public/pharmacy/medical_master/get/one?medicalMasterId=${medicalMasterId}`);
    return response.data.data as MedicalItemSchema;
}

export const getNonMedicalItemInfo = async (nonMedicalMasterId: string): Promise<NonMedicalItemSchema> => {
    const response = await httpClient.post(`${import.meta.env.VITE_API_URL as string}/public/pharmacy/non_medical_master/get/one`, {
        id: nonMedicalMasterId,
        pharmacy_code: getPharmacyID(),
    })
    return response.data.data as NonMedicalItemSchema;
}

export const getMedicalItemPrice = async (medicalMasterId: string): Promise<number> => {
    const response = await httpClient.get(`${import.meta.env.VITE_API_URL as string}/public/pharmacy/medical_master/get/current_active_price?medicalMasterId=${medicalMasterId}&pharmacyCode=${getPharmacyID()}`);
    return response.data.data as number;
}

export const submitPurchaseInvoice = async (data: AddedItemsSchema[], purchaseInvoiceData: PurchaseInvoiceSchema): Promise<boolean> => {
    try {
        let medical_items: MedicalItemAddSchema[] = []
        let non_medical_items: NonMedicalItemAddSchema[] = []
        data.forEach((item) => {
            if (item.type === "MEDICAL") {
                medical_items.push({
                    batch_number: item.batch_number,
                    cost: item.cost,
                    expiry_date: item.expiry_date ? dayjs(item.expiry_date).format("YYYY-MM-DD") : "",
                    pharmacy_code: getPharmacyID(),
                    production_date: item.production_date ? dayjs(item.production_date).format("YYYY-MM-DD") : "",
                    quantity: {
                        bonus: item.quantity.bonus,
                        original: item.quantity.original,
                    },
                    medical_master_id: item.medical_master_id!,
                    metadata: {
                        custom_barcode: item.metadata.custom_barcode || "",
                        maximum_discount_type: item.metadata.discount_type || "NONE",
                        maximum_discount_value: item.metadata.discount_value || 0,
                    }
                })
            } else if (item.type === "NON_MEDICAL") {
                non_medical_items.push({
                    batch_number: item.batch_number,
                    cost: item.cost,
                    expiry_date: item.expiry_date ? dayjs(item.expiry_date).format("YYYY-MM-DD") : "",
                    price: item.price,
                    production_date: item.production_date ? dayjs(item.production_date).format("YYYY-MM-DD") : "",
                    quantity: {
                        bonus: item.quantity.bonus,
                        original: item.quantity.original,
                    },
                    non_medical_master_id: item.non_medical_master_id!,
                    metadata: {
                        custom_barcode: item.metadata.custom_barcode || "",
                        maximum_discount_type: item.metadata.discount_type || "NONE",
                        maximum_discount_value: item.metadata.discount_value || 0,
                    }
                })
            }
        })
        await httpClient.post(`${import.meta.env.VITE_API_URL as string}/public/pharmacy/purchase_invoice/create`, {
            medical_items,
            non_medical_items,
            delivery_date: dayjs(purchaseInvoiceData.delivery_date).format("YYYY-MM-DD"),
            invoice_date: dayjs(purchaseInvoiceData.invoice_date).format("YYYY-MM-DD"),
            invoice_number: purchaseInvoiceData.invoice_number,
            payment_mode: purchaseInvoiceData.payment_mode,
            payment_type: purchaseInvoiceData.payment_type,
            supplier_id: purchaseInvoiceData.supplier_id,
            pharmacy_code: getPharmacyID(),
            purchase_order_id: purchaseInvoiceData.po_id || "",
        })
        return true
    } catch (error) {
        console.log(error)
        return false
    }
}