import AddSupplier from "./components/add-supplier";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  CaretSortIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  DoubleArrowLeftIcon,
  DoubleArrowRightIcon,
} from "@radix-ui/react-icons";
import { Route as supplierRoute } from "@/routes/suppliers/index";
import dayjs from "dayjs";
import { useNavigate } from "@tanstack/react-router";
import { SetStateAction, useState } from "react";

const Component = () => {
  const suppliers = supplierRoute.useLoaderData();
  const searchParams = supplierRoute.useSearch();
  const navigate = useNavigate();
  const [search, setSearch] = useState(searchParams.search);

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("created_at");

  const handleRequestSort = (property: SetStateAction<string>) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = suppliers.slice().sort((a, b) => {
    if (orderBy === "created_at") {
      const dateA = new Date(a.created_at).getTime();
      const dateB = new Date(b.created_at).getTime();
      return order === "asc" ? dateA - dateB : dateB - dateA;
    }
    return 0;
  });

  return (
    <>
      <div className="overflow-y-scroll h-screen pb-48">
        <div className="flex items-center justify-between">
          <div>
            <h2 className="text-2xl font-bold tracking-tight">Suppliers</h2>
            <p className="text-muted-foreground">
              Here is a list of all your suppliers.
            </p>
          </div>
          <AddSupplier />
        </div>
        <hr className="my-4" />
        <Input
          placeholder="Search Suppliers..."
          className="h-8 w-[150px] lg:w-[250px]"
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              navigate({
                search: {
                  ...searchParams,
                  search,
                },
              });
            }
          }}
        />

        <Table className="mt-4 text-sm">
          <TableHeader>
            <TableRow>
              <TableHead>Name</TableHead>
              <TableHead>Contact Details</TableHead>
              <TableHead>Credit Allowed(Yes/No)</TableHead>
              <TableHead>Credit Period</TableHead>
              <TableHead>Monthly Payment Date</TableHead>
              <TableHead
                onClick={() => handleRequestSort("created_at")}
                style={{ cursor: "pointer" }}
              >
                <div className="flex items-center">
                  <span>Created At</span>
                  {orderBy === "created_at" && (
                    <CaretSortIcon
                      className={`ml-2 h-4 w-4 ${
                        order === "asc" ? "rotate-180" : ""
                      }`}
                    />
                  )}
                </div>
              </TableHead>
              <TableHead></TableHead>
            </TableRow>
          </TableHeader>
          <TableBody
            className=""
          >
            {sortedData.map((supplier, index) => (
              <TableRow 
                key={index}
                className="cursor-pointer hover:bg-muted/50"
                onClick={() => navigate({ 
                  to: '/suppliers/$supplierId',
                  params: { supplierId: supplier.id }
                })}
              >
                <TableCell>{supplier.name}</TableCell>
                <TableCell>
                  {supplier.metadata.contact_person_name} (
                  {supplier.metadata.phone_number})
                </TableCell>
                <TableCell>
                  {supplier.credit_terms.is_credit_allowed ? "Yes" : "No"}
                </TableCell>
                <TableCell>{supplier.credit_terms.credit_period}</TableCell>
                <TableCell>
                  {supplier.credit_terms.monthly_payment_date}
                </TableCell>
                <TableCell>
                  {dayjs(supplier.created_at).format("DD MMM, YYYY")}
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <hr className="my-0" />

        <div className="flex items-center justify-center w-full fixed bottom-4 bg-white pt-6">
          <div className="flex w-[100px] items-center justify-center text-sm font-medium">
            Page {searchParams.page} of{" "}
            {suppliers.length > 0
              ? Math.ceil(suppliers[0].full_count! / searchParams.pageSize)
              : 1}
          </div>
          <div className="flex space-x-2">
            <Button
              variant="outline"
              className="hidden h-8 w-8 p-0 lg:flex"
              onClick={() => {
                navigate({
                  search: {
                    ...searchParams,
                    page: 1,
                  },
                });
              }}
              disabled={searchParams.page === 1}
            >
              <span className="sr-only">Go to first page</span>
              <DoubleArrowLeftIcon className="h-4 w-4" />
            </Button>
            <Button
              variant="outline"
              className="h-8 w-8 p-0"
              onClick={() => {
                navigate({
                  search: {
                    ...searchParams,
                    page: searchParams.page - 1,
                  },
                });
              }}
              disabled={searchParams.page === 1}
            >
              <span className="sr-only">Go to previous page</span>
              <ChevronLeftIcon className="h-4 w-4" />
            </Button>
            <Button
              variant="outline"
              className="h-8 w-8 p-0"
              onClick={() => {
                navigate({
                  search: {
                    ...searchParams,
                    page: searchParams.page + 1,
                  },
                });
              }}
              disabled={
                suppliers.length === 0 ||
                suppliers[0].full_count! <=
                  searchParams.page * searchParams.pageSize
              }
            >
              <span className="sr-only">Go to next page</span>
              <ChevronRightIcon className="h-4 w-4" />
            </Button>
            <Button
              variant="outline"
              className="hidden h-8 w-8 p-0 lg:flex"
              onClick={() => {
                navigate({
                  search: {
                    ...searchParams,
                    page: Math.ceil(
                      suppliers[0].full_count! / searchParams.pageSize
                    ),
                  },
                });
              }}
              disabled={
                suppliers.length === 0 ||
                suppliers[0].full_count! <=
                  searchParams.page * searchParams.pageSize
              }
            >
              <span className="sr-only">Go to last page</span>
              <DoubleArrowRightIcon className="h-4 w-4" />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Component;
