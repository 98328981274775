import Dexie, { EntityTable } from 'dexie';

interface ActiveSales {
    id: number;
    created_at: Date;
    selected: boolean;
}

interface SalesItems {
    id: number;
    sale_id: number;
    item_id: string;
    unit: number;
    unit_type: string;
    barcode?: string;
    trade_name: string;
    quantity_added: number;
    item_discount: number;
    type: 'MEDICAL' | 'NON_MEDICAL';
}

interface SalesItemsBatches {
    id: number;
    sale_id: number;
    item_id: string;
    inventory_id: string;
    batch_number: string;
    expiry_date?: string;
    cost: number;
    price: number;
    available_stock: number;
    sold_quantity: number;
    price_history_id: string;
    vat: number;
    type: 'MEDICAL' | 'NON_MEDICAL';
    is_selected: boolean;
    max_discount_type?: "PERCENTAGE" | "VALUE" | "ALWAYS" | "NONE";
    max_discount_amount?: number;
    offers?: string;
}

interface CreatePiInstance {
    id: number;
    complete: boolean;
    updated_at: Date;
    type: string;
}

interface AddStockInstance {
    id: number;
    complete: boolean;
    updated_at: Date;
}

interface CreatePIInfo {
    id: number;
    instance_id: number;
    invoice_date: Date;
    invoice_number: string;
    delivery_date: Date;
    payment_mode: string;
    payment_type: string;
    supplier_id: string;
    instance_type: string;
    po_id?: string;
}

interface AddStockItem {
    id: number;
    instance_id: number;
    item_id: string;
    product_name: string;
    type: string;
    batch_no: string;
    cost: number;
    price: number;
    expiry_date: string;
    production_date: string;
    custom_barcode: string;
    discount_type: string;
    vat: number;
    discount_value: number;
    quantity: number;
    bonus: number;
    instance_type: "ADD_STOCK" | "CREATE_PI" | "DRAFT_PI";
}

interface DraftPIInstance {
    id: number;
    complete: boolean;
    updated_at: Date;
    po_id: string;
}

const db = new Dexie('pos') as Dexie & {
    active_sales: EntityTable<ActiveSales, 'id'>;
    sales_items: EntityTable<SalesItems, 'id'>;
    sales_items_batches: EntityTable<SalesItemsBatches, 'id'>;
    create_pi_instance: EntityTable<CreatePiInstance, 'id'>;
    add_stock_instance: EntityTable<AddStockInstance, 'id'>;
    create_pi_info: EntityTable<CreatePIInfo, 'id'>;
    add_stock_item: EntityTable<AddStockItem, 'id'>;
    draft_pi_instance: EntityTable<DraftPIInstance, 'id'>;
}

db.version(1).stores({
    active_sales: '++id, created_at',
    sales_items: '++id, sale_id, item_id, unit, unit_type, barcode, trade_name, quantity_added, item_discount, type',
    sales_items_batches: '++id, sale_id, item_id, inventory_id, batch_number, expiry_date, cost, price, available_stock, sold_quantity, price_history_id, vat, type, is_selected, max_discount_type, max_discount_amount, offers',
    create_pi_instance: '++id, complete, updated_at, type',
    add_stock_instance: '++id, complete, updated_at',
    create_pi_info: '++id, instance_id, invoice_date, invoice_number, delivery_date, payment_mode, payment_type, supplier_id, instance_type',
    add_stock_item: '++id, instance_id, item_id, product_name, type, batch_no, cost, price, expiry_date, production_date, custom_barcode, discount_type, vat, discount_value, quantity, bonus, instance_type',
    draft_pi_instance: '++id, complete, updated_at, po_id'
})

export type {
    ActiveSales,
    SalesItems,
    SalesItemsBatches,
    CreatePiInstance,
    AddStockInstance,
    CreatePIInfo,
    AddStockItem
};
export default db;