import { useEffect, useMemo, useState } from 'react'
import * as z from "zod";
import { CalendarIcon, Pen } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { toast } from "@/components/ui/use-toast";
import { cn } from "@/lib/utils";
import { format } from "date-fns";
import { Calendar } from "@/components/ui/calendar";
import { AutoComplete, type Option } from "@/components/styled-autocomplete.tsx"
import { AddNonMedical } from '@/components/add-non-medical';
import { SearchAllSchema } from '@/screens/create-purchase-invoice/components/data/schema';
import getPharmacyID from '@/utils/pharmacy_code';
import { httpClient } from '@/httpClient';
import { useDebounce } from '@uidotdev/usehooks';
import ActionAndInfo from './action-and-info'
import { getMedicalItemPrice } from '../../item/api';
import { AddedItemsSchema } from '../schema';
import { NumericString, numericStringDefault, stringToNumericString } from '@/utils/numeric_string';
import { LoadState } from '@/utils/load_state';
import { Loading } from '@/layouts/loading';
import dayjs from 'dayjs';

export type AddItemForm = {
  item_id: string,
  trade_name: string,
  type: "MEDICAL" | "NON_MEDICAL",
  search: Option
  batchNo: string,
  expiryDate?: string,
  productionDate?: string,
  cost: NumericString,
  price: NumericString,
  customBarcode: string,
  discountType: "NONE" | "PERCENTAGE" | "VALUE" | "ALWAYS",
  discountValue: NumericString,
  quantity: NumericString,
  bonus: NumericString,
  vat: number
}

export const checkDuplicateBatch = (
  items: AddedItemsSchema[],
  batchNo: string,
  id?: string,
  type?: "MEDICAL" | "NON_MEDICAL",
): boolean => {
  let count = 0;
  for (let item of items) {
    if (item.batch_number === batchNo && item.medical_master_id === id && type === "MEDICAL") {
      count++;
    }
    if (item.batch_number === batchNo && item.non_medical_master_id === id && type === "NON_MEDICAL") {
      count++;
    }
    if (count > 1) {
      return true
    }
  }
  return false;
};

export const formValidator = (data: AddItemForm, items: AddedItemsSchema[], mode: "ADD" | "EDIT"): {
  status: boolean,
  message: string,
} => {
  if (data.trade_name === "") {
    return {
      status: false,
      message: "Trade name is required"
    }
  }
  if (data.batchNo === "") {
    return {
      status: false,
      message: "Batch number is required"
    }
  }
  if (data.cost.value <= 0) {
    return {
      status: false,
      message: "Cost must be positive"
    }
  }
  if (data.productionDate === undefined) {
    return {
      status: false,
      message: "Production date is required"
    }
  }
  if (data.type === "NON_MEDICAL" && (data.price.value === 0 || data.price.value === undefined)) {
    return {
      status: false,
      message: "Price is required for non-medical products"
    }
  }
  if (data.type === "MEDICAL" && data.expiryDate === undefined) {
    return {
      status: false,
      message: "Expiry date is required for medical products"
    }
  }
  if (data.quantity.value <= 0) {
    return {
      status: false,
      message: "Original quantity must be more than 0"
    }
  }
  if (data.bonus.value < 0) {
    return {
      status: false,
      message: "Bonus quantity must be greater or equal to 0"
    }
  }
  if (data.cost.value < 0) {
    return {
      status: false,
      message: "Cost must be greater or equal to 0"
    }
  }
  if (data.discountType !== "NONE" && data.discountValue.value <= 0) {
    return {
      status: false,
      message: "Discount value must be greater than 0"
    }
  }
  if (mode === "ADD") {
    if (checkDuplicateBatch(items, data.batchNo, data.item_id, data.type as "MEDICAL" | "NON_MEDICAL")) {
      return {
        status: false,
        message: "Duplicate batch number"
      }
    }

  }
  return {
    status: true,
    message: ""
  };
}


export const addedItemsValidator = (globalItems: AddedItemsSchema[]) => {
  if (globalItems.length == 0) {
    return false
  }
  for (let item of globalItems) {
    //Quantity original should not be 0 bonus can be 0
    //Cost must be greater than 0 for both
    //Batch number is compulsory
    if (item.cost === 0 || item.quantity.original === 0 || item.batch_number === "" || item.trade_name === "" || (item.metadata.discount_type !== "NONE" && item.metadata.discount_value === 0)) {
      return false
    }
    //Non medical items price should not be 0
    if (item.type === "NON_MEDICAL") {
      if (item.price === undefined || item.price === 0) {
        return false
      }
    }
    // Production date optional for both medical and non medical
    if (item.production_date !== undefined) {
      //Production date should not be in the future
      if (dayjs(item.production_date).startOf('day').isAfter(dayjs().startOf('day'))) {
        return false
      }
    }
    //Expiry date compulsory for medical items optional for non medical
    if (item.expiry_date === undefined) {
      if (item.type === "MEDICAL") {
        return false
      }
    } else {
      //Expiry date should not be in the past or before production date
      const expiry_date = dayjs(item.expiry_date).startOf('day')
      if (expiry_date.isBefore(dayjs().startOf('day'))) {
        return false
      }
      if (item.production_date) {
        const production_date = dayjs(item.production_date).startOf('day')
        if (expiry_date.isBefore(production_date)) {
          return false
        }
      }
    }
    // no duplicate batch numbers
    if (checkDuplicateBatch(globalItems, item.batch_number, item.type === "MEDICAL" ? item.medical_master_id : item.non_medical_master_id, item.type)) {
      return false
    }
  }
  return true
}

export const AddedItemToForm = (item: AddedItemsSchema): AddItemForm => {
  const id = (item.medical_master_id || item.non_medical_master_id)!;
  return {
    item_id: id,
    trade_name: item.trade_name,
    type: item.medical_master_id ? "MEDICAL" : "NON_MEDICAL",
    search: {
      label: item.trade_name,
      value: id,
      type: item.type,
      vat: item.vat.toString()
    },
    batchNo: item.batch_number,
    cost: {
      value: item.cost,
      strValue: item.cost.toString()
    },
    price: {
      value: item.price || 0,
      strValue: item.price?.toString() || "0"
    },
    expiryDate: item?.expiry_date?.toString() || undefined,
    productionDate: item?.production_date?.toString() || undefined,
    customBarcode: item.metadata.custom_barcode || "",
    discountType: item.metadata.discount_type || "NONE",
    discountValue: {
      value: item.metadata.discount_value || 0,
      strValue: item.metadata.discount_value?.toString() || "0"
    },
    quantity: {
      value: item.quantity.original,
      strValue: item.quantity.original.toString()
    },
    bonus: {
      value: item.quantity.bonus,
      strValue: item.quantity.bonus.toString()
    },
    vat: Number(item.vat)
  }
}

const Add = ({ items, editItem, addItem, injectItem, resetInjectItem }: {
  items: AddedItemsSchema[]
  addItem: (newItem: AddItemForm) => void
  editItem: (newItem: AddItemForm, dbItemId: number) => void
  injectItem?: AddedItemsSchema
  resetInjectItem?: () => void
}) => {
  const [search, setSearch] = useState<string>('');
  const [results, setResults] = useState<Option[]>([]);
  const debouncedSearchTerm = useDebounce(search, 300);

  const defaultFormObj: AddItemForm = {
    item_id: "",
    trade_name: "",
    type: "MEDICAL",
    search: {
      label: "",
      value: ""
    },
    batchNo: "",
    cost: numericStringDefault(),
    price: numericStringDefault(),
    expiryDate: undefined,
    productionDate: undefined,
    customBarcode: "",
    discountType: "NONE",
    vat: 0,
    discountValue: numericStringDefault(),
    quantity: numericStringDefault(),
    bonus: numericStringDefault()
  }
  const [formObj, setFormObj] = useState(defaultFormObj)
  const formDisabled = useMemo(() => formObj.trade_name === "", [formObj.trade_name])
  const [mode, setMode] = useState<"ADD" | "EDIT">(injectItem ? "EDIT" : "ADD")
  const [loadingDetails, setLoadingDetails] = useState(LoadState.None)
  const { status: formValidity, message: errorMessage } = useMemo(() => {
    return formValidator(formObj, items, mode)
  }, [formObj, items])


  const resetForm = () => {
    setFormObj(defaultFormObj);
    setSearch('');
    resetInjectItem && resetInjectItem();
  };



  const onSubmit = (formObj: AddItemForm) => {
    if (!formValidity) {
      toast({
        title: "Error",
        description: errorMessage,
        duration: 5000,
      });
      return;
    }
    if (mode === "ADD") {
      addItem(formObj);
    }
    if (mode === "EDIT") {
      editItem(formObj, injectItem!.db_item_id);
    }
    resetForm();
    setMode("ADD");
  };

  useEffect(() => {
    try {
      const getMeds = async () => {
        if (debouncedSearchTerm.length === 0) {
          setResults([]);
          return;
        }
        if (debouncedSearchTerm.length < 3) return;

        const response = await httpClient.post(
          `${import.meta.env.VITE_API_URL as string
          }/public/pharmacy/search/all`,
          {
            pharmacy_code: getPharmacyID(),
            query: debouncedSearchTerm,
            limit: 20,
          }
        );
        const data = response.data.data as SearchAllSchema[];
        setResults(data.map((item) => ({ label: item.trade_name + " " + item.unit_type + ":::" + item.manufacturer + ":::" + item.owner, value: item.id, type: item.type, vat: item.vat.toString(), trade_name: item.trade_name })));
      };
      getMeds();
    } catch (error) {
      console.error("Error:", error);
      toast({
        title: "Error",
        description: "Failed to search",
        duration: 5000,
      });
    }
  }, [debouncedSearchTerm]);
  useEffect(() => {
    let price = injectItem?.price;
    if (injectItem) {
      if (injectItem.type === "MEDICAL" && !price) {
        setLoadingDetails(LoadState.Loading)
        getMedicalItemPrice(injectItem.medical_master_id!).then((price) => {
          setMode("EDIT")
          setFormObj(AddedItemToForm({ ...injectItem, price: price }))
          setLoadingDetails(LoadState.Loaded)

        }).catch(() => {
          setLoadingDetails(LoadState.Error)
        })
      } else {
        setMode("EDIT");
        setFormObj(AddedItemToForm({ ...injectItem, price: price }));

      }
    }
  }, [injectItem])
  const [customBarcodeGenerating, setCustomBarcodeGenerating] = useState(false);
  return (
    <div className='bg-[#F6F6F6] rounded-sm p-4 h-full overflow-auto'>
      {
        loadingDetails === LoadState.Loading &&
        <div className='w-full h-full flex items-center justify-center'>
          <Loading />
        </div>
      }
      {
        loadingDetails === LoadState.Error &&
        <div className='w-full h-full flex items-center justify-center'>
          <p className='text-red-500'>Failed to load item details</p>
        </div>
      }
      {
        loadingDetails !== LoadState.Loading &&
        <form onSubmit={(e) => {
          e.preventDefault()
          onSubmit(formObj)
        }} className="w-full flex flex-col gap-4 mt-4 mb-24">
          <div className='flex items-center justify-between'>
            <p className="text-sm font-semibold">
              Product Name<span className="text-red-400">*</span>
            </p>
            <AddNonMedical />
          </div>
          <div className='space-y-2'>
            <div className='border rounded-md px-2 flex items-center bg-white'>
              {
                mode == "EDIT" ?
                  <div className='w-full flex flex-row gap-2 min-h-11 py-1 items-center'>
                    <div>
                      <Pen className='w-4 h-4' />
                    </div>
                    <p>Editing info for {formObj.trade_name}</p>
                  </div>
                  :
                  <AutoComplete
                    options={results}
                    emptyMessage="No results."
                    placeholder="Search for a product"
                    onValueChange={(value) => {
                      // field.onChange(value);
                      console.log(value);
                      //vat for displaying in the generated pdf
                      if (value?.vat) {
                        setFormObj({
                          ...formObj,
                          vat: Number(value.vat)
                        });
                      }
                      if (value?.type === "MEDICAL") {
                        getMedicalItemPrice(value.value).then((price) => {
                          setFormObj({
                            ...formObj,
                            item_id: value.value,
                            trade_name: value.trade_name,
                            type: value.type as "MEDICAL" | "NON_MEDICAL",
                            search: value,
                            price: {
                              value: price,
                              strValue: price.toString()
                            }
                          });
                        });
                      }
                      if (!value) {
                        setSearch('');
                        setFormObj(defaultFormObj);
                      }
                      setFormObj({
                        ...formObj,
                        item_id: value.value,
                        trade_name: value.trade_name,
                        type: value.type as "MEDICAL" | "NON_MEDICAL",
                        search: value,
                      })
                    }}
                    value={formObj.search}
                    onInputChanged={(value) => {
                      setSearch(value);
                      if (!value) {
                        setFormObj(defaultFormObj);
                      }
                    }}
                    shouldFilter={false}
                    input={search}
                  />
              }
              <ActionAndInfo value={
                {
                  id: formObj.item_id,
                  type: formObj.type,
                }
              } />
            </div>
          </div>
          <hr className="" />
          <div>
            <p className="text-sm font-semibold mb-2">
              Batch No <span className="text-red-400">*</span>
            </p>
            <div
              className='space-y-2'
            >
              <Input
                placeholder="Enter batch number"
                disabled={formDisabled}
                value={formObj.batchNo}
                onChange={(e) => {
                  setFormObj({
                    ...formObj,
                    batchNo: e.target.value
                  })
                }}
              />
            </div>
          </div>
          <div>
            <p className="text-sm font-semibold mb-2">
              Expiry Date <span className="text-red-400">*</span>
            </p>
            <div className='space-y-2'>
              <Popover>
                <PopoverTrigger asChild>
                  <Button
                    variant={"outline"}
                    disabled={formDisabled}
                    className={cn(
                      "w-full pl-3 text-left font-normal",
                      !formObj.expiryDate && "text-muted-foreground"
                    )}
                  >
                    {formObj.expiryDate ? (
                      format(formObj.expiryDate, "PPP")
                    ) : (
                      <span>Expiry date</span>
                    )}
                    <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                  </Button>
                </PopoverTrigger>
                <PopoverContent
                  className="w-auto p-0"
                  align="start"
                >
                  <Calendar
                    mode="single"
                    selected={new Date(formObj.expiryDate!)}
                    fromDate={(() => {
                      const today = dayjs().startOf('day');
                      if (formObj.productionDate) {
                        const productionDate = dayjs(formObj.productionDate).startOf('day');
                        if (productionDate.isAfter(today)) {
                          return productionDate.toDate();
                        }
                      }
                      return today.toDate();
                    })()}
                    onSelect={(newDate) => {
                      setFormObj({
                        ...formObj,
                        expiryDate: newDate?.toString()
                      })
                    }}
                  />
                </PopoverContent>
              </Popover>
            </div>
          </div>
          <div>
            <p className="text-sm font-semibold mb-2">
              Production Date
            </p>
            <div className='space-y-2'>
              <Popover>
                <PopoverTrigger asChild>
                  <Button
                    variant={"outline"}
                    disabled={formDisabled}
                    className={cn(
                      "w-full pl-3 text-left font-normal",
                      !formObj.productionDate && "text-muted-foreground"
                    )}
                  >
                    {formObj.productionDate ? (
                      format(formObj.productionDate, "PPP")
                    ) : (
                      <span>Production Date</span>
                    )}
                    <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                  </Button>
                </PopoverTrigger>
                <PopoverContent
                  className="w-auto p-0"
                  align="start"
                >
                  <Calendar
                    mode="single"
                    selected={new Date(formObj.productionDate!)}
                    toDate={dayjs().startOf('day').toDate()}
                    onSelect={(newDate) => {
                      let expiryDateForm = formObj.expiryDate;
                      if (formObj.expiryDate) {
                        const expiryDate = dayjs(formObj.expiryDate).startOf('day');
                        const productionDate = dayjs(newDate).startOf('day');
                        if (productionDate.isAfter(expiryDate)) {
                          expiryDateForm = undefined
                        }
                      }
                      setFormObj({
                        ...formObj,
                        expiryDate: expiryDateForm,
                        productionDate: newDate?.toString()
                      })
                    }}
                  />
                </PopoverContent>
              </Popover>
            </div>
          </div>
          <hr className='my-2' />
          <div>
            <p className="text-sm font-semibold mb-2">
              Original <span className="text-red-400">*</span>
            </p>
            <div className='space-y-2'>
              <Input
                placeholder="Enter original quantity"
                disabled={formDisabled}
                value={formObj.quantity.strValue}
                onChange={(e) => {
                  stringToNumericString(e.target.value, (value) => {
                    setFormObj({
                      ...formObj,
                      quantity: value
                    })
                  });
                }}
              />
            </div>
          </div>
          <div>
            <p className="text-sm font-semibold mb-2">
              Bonus
            </p>
            <div className='space-y-2'>
              <Input
                placeholder="Enter bonus quantity"
                disabled={formDisabled}
                value={formObj.bonus.strValue}
                onChange={(e) => {
                  stringToNumericString(e.target.value, (value) => {
                    setFormObj({
                      ...formObj,
                      bonus: value
                    })
                  });
                }}
              />
            </div>
          </div>
          <hr className='my-2' />
          <div>
            <p className="text-sm font-semibold mb-2">
              Cost <span className="text-red-400">*</span>
            </p>
            <div className='space-y-2'>
              <Input
                placeholder="Enter seller cost"
                value={formObj.cost.strValue}
                disabled={formDisabled}
                onChange={(e) => {
                  stringToNumericString(e.target.value, (value) => {
                    setFormObj({
                      ...formObj,
                      cost: value
                    })
                  });
                }}
              />
            </div>
          </div>
          <div>
            <p className="text-sm font-semibold mb-2">
              Price <span className="text-red-400">{formObj.type === "NON_MEDICAL" ? "*" : ""}</span>
            </p>
            <div className='space-y-2'>
              <Input
                placeholder="Enter selling price"
                value={formObj.price.strValue}
                disabled={formDisabled || formObj.type === "MEDICAL"}
                onChange={(e) => {
                  stringToNumericString(e.target.value, (value) => {
                    setFormObj({
                      ...formObj,
                      price: value
                    })
                  });
                }}
              />
            </div>
          </div>
          <hr className='my-2' />
          <div>
            <p className="text-sm font-semibold mb-2">
              Custom Barcode
            </p>
            <div className='space-y-2'>
              <Input
                placeholder="Enter custom barcode"
                disabled={formDisabled || customBarcodeGenerating}
                value={formObj.customBarcode}
                onChange={(e) => {
                  setFormObj({
                    ...formObj,
                    customBarcode: e.target.value
                  })
                }}
              />
            </div>
            <div className="flex justify-end">
              <Button variant={"outline"} size={"sm"} onClick={async () => {
                try {
                  setCustomBarcodeGenerating(true)
                  if (formObj.type === "MEDICAL") {
                    const response = await httpClient.post(`${import.meta.env.VITE_API_URL as string}/public/pharmacy/inventory/medical/generate_barcode`, {
                      pharmacy_code: getPharmacyID(),
                    });
                    const barcode = response.data.data as string;
                    setFormObj({
                      ...formObj,
                      customBarcode: barcode
                    })
                  } else {
                    const response = await httpClient.post(`${import.meta.env.VITE_API_URL as string}/public/pharmacy/inventory/non_medical/generate_barcode`, {
                      pharmacy_code: getPharmacyID(),
                    });
                    const barcode = response.data.data as string;
                    setFormObj({
                      ...formObj,
                      customBarcode: barcode
                    })
                  }
                } catch (e) {
                  alert("Failed to generate barcode")
                  console.error(e)
                } finally {
                  setCustomBarcodeGenerating(false)
                }
              }} disabled={formDisabled || customBarcodeGenerating}>{customBarcodeGenerating ? "Please Wait..." : "Generate Barcode"}</Button>
            </div>
          </div>
          <div>
            <p className="text-sm font-semibold mt-4 mb-2">
              Discount Type
            </p>
            <div className='space-y-2'>
              <Select
                value={formObj.discountType}
                onValueChange={(value: "NONE" | "PERCENTAGE" | "VALUE" | "ALWAYS") => {
                  const discountType = value;
                  let discountValue = formObj.discountValue;
                  if (discountType === "NONE") {
                    discountValue = numericStringDefault();
                  }
                  setFormObj({
                    ...formObj,
                    discountType: discountType,
                    discountValue: discountValue
                  })
                }}
                disabled={formDisabled}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select Discount Type" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="NONE">None</SelectItem>
                  <SelectItem value="PERCENTAGE">
                    Percentage
                  </SelectItem>
                  <SelectItem value="VALUE">Value</SelectItem>
                  <SelectItem value="ALWAYS">Always</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>
          <div>
            <p className="text-sm font-semibold mb-2">
              Discount Value
            </p>
            <div className='space-y-2'>
              <Input
                placeholder="Enter discount value"
                value={formObj.discountValue.strValue}
                onChange={(e) => {
                  stringToNumericString(e.target.value, (value) => {
                    setFormObj({
                      ...formObj,
                      discountValue: value
                    })
                  })
                }}
                disabled={
                  formDisabled || formObj.discountType === "NONE" || formObj.discountType === undefined
                }
              />
            </div>
          </div>
          <hr />
          <Button type="submit" className="bg-blue-700"
            disabled={!formValidity}
          >
            Confirm & {
              mode === "ADD" ? "Add" : "Save"
            }
          </Button>
          <Button type="button" variant={'secondary'} className="mt-4" onClick={resetForm}>
            Cancel
          </Button>
        </form>
      }  </div>
  );
};

export default Add;