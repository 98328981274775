import db from "@/db";
import { dateToDB } from "../time_format";

export type PIInfoRow = Partial<{
    invoice_date: Date;
    invoice_number: string;
    delivery_date: Date;
    payment_mode: string;
    payment_type: string;
    supplier_id: string;
    po_id: string;
}> & { id: number, instance_id: number };

export type DraftPIInstanceRow = {
    id: number;
    updated_at: string;
    completed: boolean;
    po_id: string;
}


export function setPIInfo(params: Omit<PIInfoRow, "instance_id" | "id"> & { instance_id?: number }, onSuccess: (instance_id: number) => void, onError?: (error: any) => void) {
    console.log(params)
    const instance_id = params.instance_id
    if (instance_id) {
        db.create_pi_info.update(instance_id, {
            invoice_date: params.invoice_date,
            invoice_number: params.invoice_number,
            delivery_date: params.delivery_date,
            payment_mode: params.payment_mode,
            payment_type: params.payment_type,
            supplier_id: params.supplier_id,
            po_id: params.po_id
        }).then(() => {
            onSuccess(instance_id)
        }).catch(onError ? onError : () => { })
        return;
    }
    db.transaction('rw', db.create_pi_instance, db.create_pi_info, async (tx) => {
        const instance_id = await tx.create_pi_instance.add({ complete: false, updated_at: new Date(), type: "CREATE_PI" })
        await tx.create_pi_info.add({
            instance_id,
            invoice_date: params.invoice_date!,
            invoice_number: params.invoice_number!,
            delivery_date: params.delivery_date!,
            payment_mode: params.payment_mode!,
            payment_type: params.payment_type!,
            supplier_id: params.supplier_id!,
            instance_type: "CREATE_PI",
            po_id: params.po_id!
        })
        return instance_id
    }).then((v) => onSuccess(v)).catch(onError ? onError : () => { })
}
export function setDraftPIInfo(params: Omit<PIInfoRow, "instance_id" | "id"> & { instance_id?: number }, poId: string, onSuccess: (instance_id: number) => void, onError?: (error: any) => void) {
    console.log(params)
    const instance_id = params.instance_id
    if (instance_id) {
        db.create_pi_info.update(instance_id, {
            invoice_date: params.invoice_date,
            invoice_number: params.invoice_number,
            delivery_date: params.delivery_date,
            payment_mode: params.payment_mode,
            payment_type: params.payment_type,
            supplier_id: params.supplier_id,
            po_id: params.po_id,
        }).then(() => {
            onSuccess(instance_id)
        }).catch(onError ? onError : () => { })
        return;
    }
    db.transaction('rw', db.draft_pi_instance, db.create_pi_info, async (tx) => {
        const instance_id = await tx.draft_pi_instance.add({ complete: false, updated_at: new Date(), po_id: poId })
        await tx.create_pi_info.add({
            instance_id,
            invoice_date: params.invoice_date!,
            invoice_number: params.invoice_number!,
            delivery_date: params.delivery_date!,
            payment_mode: params.payment_mode!,
            payment_type: params.payment_type!,
            supplier_id: params.supplier_id!,
            instance_type: "DRAFT_PI",
            po_id: params.po_id!
        })
        return instance_id
    }).then((v) => onSuccess(v)).catch(onError ? onError : () => { })

}
export function completeDraftPI(instanceId: number, onSuccess: () => void, onError?: (error: any) => void) {
    db.transaction('rw', db.draft_pi_instance, db.create_pi_info, db.add_stock_item, async (tx) => {
        await tx.draft_pi_instance.delete(instanceId)
        await tx.create_pi_info.where({ instance_id: instanceId }).delete()
        await tx.add_stock_item.where({ instance_id: instanceId }).delete()
    }).then(onSuccess).catch(onError ? onError : () => { })
}
export function completeCreatePIInstance(instanceId: number, onSuccess: () => void, onError?: (error: any) => void) {
    db.transaction('rw', db.create_pi_instance, db.create_pi_info, db.add_stock_item, async (tx) => {
        await tx.create_pi_instance.update(instanceId, { complete: true, updated_at: new Date() })
        await tx.create_pi_info.where({ instance_id: instanceId }).delete()
        await tx.add_stock_item.where({ instance_id: instanceId }).delete()
    }).then(onSuccess).catch(onError ? onError : () => { })
}

export function completeAddStockInstance(instanceId: number, onSuccess: () => void, onError?: (error: any) => void) {
    db.transaction('rw', db.add_stock_instance, db.add_stock_item, async (tx) => {
        await tx.add_stock_instance.update(instanceId, { complete: true, updated_at: new Date() })
        await tx.add_stock_item.where({ instance_id: instanceId }).delete()
    }).then(onSuccess).catch(onError ? onError : () => { })

}
