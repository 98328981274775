import { useState, useEffect, useRef, useMemo } from 'react'
import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'
import { QuantityModal } from './components/quantity-modal';
import { AutoComplete, type Option } from "@/components/styled-autocomplete"
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"
import { Label } from "@/components/ui/label"
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select"


import { httpClient } from '@/httpClient'
import Footer from './components/footer'

import { CheckCircle, DeleteIcon } from 'lucide-react'
import getPharmacyID from '@/utils/pharmacy_code'
import { SearchAvailableSchema } from './models/search'
import { CompulsoryFields, InventoryCombinedData, Offer } from './models/inventoryData'
import { convertToCurrencyRounded, getDiscount, getItemCost } from '@/utils/currency_converter'
import { toast } from '@/components/ui/use-toast'
import { getMedicalItem, getNonMedicalItem } from './api'
import { useDebounce } from "@uidotdev/usehooks";
import dayjs from 'dayjs'
import StartShiftModal from '@/components/shifts/start-shift-modal'
import NoShiftFound from './components/no-shift-found'
import { Route as salesRoute } from '@/routes/sales/index'
import { ContextMenu, ContextMenuContent, ContextMenuItem, ContextMenuTrigger } from '@/components/ui/context-menu'
import { useHotkeys } from 'react-hotkeys-hook';
import NoSaleSelected from './components/no-sale-selected'
import { DiscountModal } from './components/discount-modal';
import db from '@/db';
import { useLiveQuery } from 'dexie-react-hooks';
import { useNavigate } from '@tanstack/react-router';

const component = () => {
    const navigate = useNavigate();
    const data = salesRoute.useLoaderData();
    const [search, setSearch] = useState('')
    const [results, setResults] = useState<Option[]>([])
    const [selected, setSelected] = useState<InventoryCombinedData[]>([])
    const [switchInsurance, setSwitchInsurance] = useState(false)
    const [emiratesID, setEmiratesID] = useState('')
    const [value, setValue] = useState<Option>()
    const [openShiftStart, setOpenShiftStart] = useState(!data.activeShiftFound) // Needs to be checked from backend @nanduri1998
    const [displaySalesComp, setDisplaySalesComp] = useState(data.activeShiftFound) // Needs to be checked from backend @nanduri1998
    const debouncedSearchTerm = useDebounce(search, 300);
    const autoCompleteRef = useRef<HTMLInputElement>(null);
    const checkoutButtonRef = useRef<HTMLButtonElement>(null);

    const [selectedItemIndex, setSelectedItemIndex] = useState(-1);

    const active_sales = useLiveQuery(() => db.active_sales.toArray());
    const selected_sale = useLiveQuery(() => db.active_sales.filter(a => a.selected).first());
    const selected_items = useLiveQuery(() => db.sales_items.toArray());
    const selected_items_batches = useLiveQuery(() => db.sales_items_batches.toArray());

    useHotkeys('up', (event) => {
        event.preventDefault();
        setSelectedItemIndex((prevIndex) => {
            const newIndex = prevIndex > 0 ? prevIndex - 1 : 0;
            return newIndex;
        });
    });

    useHotkeys('down', (event) => {
        event.preventDefault();
        setSelectedItemIndex((prevIndex) => {
            const maxIndex = (selected_items?.filter(a => a.sale_id === selected_sale?.id).length || 0) - 1;
            const newIndex = prevIndex < maxIndex ? prevIndex + 1 : maxIndex;
            return newIndex;
        });
    });

    useHotkeys('s', (event) => {
        event.preventDefault();
        // Cycle between active_sales
        if (active_sales && active_sales.length === 1) return;
        const currentIndex = selected_sale && active_sales?.findIndex(a => a.id === selected_sale?.id);
        if (currentIndex === active_sales?.length! - 1) {
            (selected_sale && active_sales) && db.active_sales.update(selected_sale?.id, { selected: true });
        } else {
            (selected_sale && active_sales) && db.active_sales.update(selected_sale?.id, { selected: false });
        }
    });

    useHotkeys('space', (event) => {
        event.preventDefault();
        autoCompleteRef.current?.focus();
    });

    useHotkeys('shift+enter', (event) => {
        event.preventDefault();
        if (checkoutButtonRef.current) {
            checkoutButtonRef.current.click();
        } else {
            console.log('Checkout button ref is null');
        }
    });

    useHotkeys('shift+s', (event) => {
        event.preventDefault();
        addNewMultiSale();
    });

    useHotkeys('shift+d', (event) => {
        event.preventDefault();
        if (active_sales?.length === 1) return;
        try {
            db.transaction('rw', db.active_sales, db.sales_items, db.sales_items_batches, async (tx) => {
                const to_beDeleted_sale = await tx.active_sales.where('id').equals(selected_sale?.id!).toArray();
                const to_beDeletedSaleItems = await tx.sales_items.where('sale_id').equals(selected_sale?.id!).toArray();
                const to_beDeletedSaleItemsBatches = await tx.sales_items_batches.where('sale_id').equals(selected_sale?.id!).toArray();
                await tx.active_sales.bulkDelete(to_beDeleted_sale.map(a => a.id));
                await tx.sales_items.bulkDelete(to_beDeletedSaleItems.map(a => a.id));
                await tx.sales_items_batches.bulkDelete(to_beDeletedSaleItemsBatches.map(a => a.id));
                active_sales && await tx.active_sales.update(active_sales[0].id, { selected: true });
            })
        } catch (e) {
            console.error(e)
        }
    });

    useHotkeys('+,right', (event) => {
        event.preventDefault();
        if (selectedItemIndex >= 0 && selected_items) {
            const selectedItem = selected_items.filter(a => a.sale_id === selected_sale?.id)[selectedItemIndex];
            if (selectedItem) {
                try {
                    db.transaction('rw', db.active_sales, db.sales_items, db.sales_items_batches, async (tx) => {
                        const quantity = await tx.sales_items.where('sale_id').equals(selected_sale?.id!).and(item => item.item_id === selectedItem.item_id).toArray();
                        const availableStock = selected_items_batches?.find((batch) => batch.item_id === selectedItem.item_id && batch.is_selected && batch.sale_id === selectedItem.sale_id)?.available_stock!
                        if (quantity[0].quantity_added === availableStock) {
                            toast({
                                title: "Error",
                                description: "Cannot add more than available stock",
                                duration: 5000,
                            });
                            return;
                        }
                        await tx.sales_items.update(quantity[0].id, { quantity_added: quantity[0].quantity_added + 1 });
                    })
                } catch (e) {
                    console.error(e)
                }
            }
        }
    });

    useHotkeys('-,left', (event) => {
        event.preventDefault();
        if (selectedItemIndex >= 0 && selected_items) {
            const selectedItem = selected_items.filter(a => a.sale_id === selected_sale?.id)[selectedItemIndex];
            if (selectedItem) {
                try {
                    db.transaction('rw', db.active_sales, db.sales_items, db.sales_items_batches, async (tx) => {
                        const quantity = await tx.sales_items.where('sale_id').equals(selected_sale?.id!).and(item => item.item_id === selectedItem.item_id).toArray();
                        if (quantity[0].quantity_added === 1) {
                            toast({
                                title: "Error",
                                description: "Cannot reduce quantity less than 1",
                                duration: 5000,
                            });
                            return;
                        }
                        await tx.sales_items.update(quantity[0].id, { quantity_added: quantity[0].quantity_added - 1 });
                    })
                } catch (e) {
                    console.error(e)
                }
            }
        }
    });

    useHotkeys('shift+backspace', (event) => {
        event.preventDefault();
        if (selectedItemIndex >= 0 && selected_items) {
            const selectedItem = selected_items.filter(a => a.sale_id === selected_sale?.id)[selectedItemIndex];
            if (selectedItem) {
                try {
                    db.transaction('rw', db.active_sales, db.sales_items, db.sales_items_batches, async (tx) => {
                        const quantity = await tx.sales_items.where('sale_id').equals(selected_sale?.id!).and(item => item.item_id === selectedItem.item_id).toArray();
                        await tx.sales_items.delete(quantity[0].id);
                        const batch = await tx.sales_items_batches.where('sale_id').equals(selected_sale?.id!).and(item => item.item_id === selectedItem.item_id).toArray();
                        await tx.sales_items_batches.delete(batch[0].id);
                    })
                } catch (e) {
                    console.error(e)
                }
            }
        }
    });


    const addNewMultiSale = async () => {
        await db.transaction('rw', db.active_sales, async (tx) => {
            const activeNumberOfSales = await db.active_sales.count();
            if (activeNumberOfSales >= 3) {
                toast({
                    title: "Error",
                    description: "Cannot add more than 3 sales",
                    duration: 5000,
                });
                return;
            }
            if (active_sales?.length === 0) {
                await tx.active_sales.add({ created_at: new Date(), selected: true });
                return;
            }
            await tx.active_sales.add({ created_at: new Date(), selected: false });
        })
    }

    const { netTotal, totaldiscount, totalvat } = useMemo(() => {
        let netTotal = 0;
        let totaldiscount = 0;
        let totalvat = 0;
        if (!selected_items) {
            return {
                netTotal, totaldiscount, totalvat
            }
        }
        if (!selected_items_batches) {
            return {
                netTotal, totaldiscount, totalvat
            }
        }
        for (let item of selected_items.filter(a => a.sale_id === selected_sale?.id)) {
            const price = selected_items_batches?.find((batch) => batch.item_id === item.item_id && batch.is_selected && batch.sale_id === item.sale_id)?.price!;
            const vat = selected_items_batches?.find((batch) => batch.item_id === item.item_id && batch.is_selected && batch.sale_id === item.sale_id)?.vat!;
            const available_stock = selected_items_batches?.find((batch) => batch.item_id === item.item_id && batch.is_selected && batch.sale_id === item.sale_id)?.available_stock!;
            const sold_quantity = selected_items_batches?.find((batch) => batch.item_id === item.item_id && batch.is_selected && batch.sale_id === item.sale_id)?.sold_quantity!;
            const inventory_id = selected_items_batches?.find((batch) => batch.item_id === item.item_id && batch.is_selected && batch.sale_id === item.sale_id)?.inventory_id;
            const discount_type = selected_items_batches?.find((batch) => batch.item_id === item.item_id && batch.is_selected && batch.sale_id === item.sale_id)?.max_discount_type;
            const item_cost = getItemCost({
                price: price,
                quantity: item.quantity_added!,
                vat: vat,
                discount: 0,
                discount_type: discount_type!
            })
            netTotal = netTotal + item_cost;
            const item_discount = getDiscount({
                price: price,
                quantity: item.quantity_added!,
                discount_amt: item.item_discount!,
                discount_type: discount_type!
            })
            totaldiscount = totaldiscount + item_discount;
            const itemVat = (item_cost - item_discount) * vat / 100;
            totalvat = totalvat + itemVat;
        }
        return {
            netTotal,
            totaldiscount,
            totalvat
        }
    }, [selected_items, selected_sale, selected_items_batches])
    useEffect(() => {
        try {
            const getMeds = async () => {
                const response = await httpClient.post(
                    `${import.meta.env.VITE_API_URL as string}/public/pharmacy/search/available`,
                    {
                        pharmacy_code: getPharmacyID(),
                        query: debouncedSearchTerm,
                        limit: 10,
                    }
                );
                const finalData = response.data.data as SearchAvailableSchema[]
                setResults(finalData.map((item) => {
                    const name = item.trade_name + (item.barcode ? " | " + item.barcode : "") + " | " + item.unit_type + ":::" + item.manufacturer + ":::" + item.owner
                    return {
                        value: item.id,
                        label: name,
                        type: item.type,
                    }
                }))
            }
            if (data.activeShiftFound) {
                getMeds()
            }
        } catch (error) {
            console.error('Error:', error);
            toast({
                title: "Error",
                description: "Failed to search",
                duration: 5000,
            });
        }
    }, [debouncedSearchTerm])

    useEffect(() => {
        if (data) {
            if (data.last_shift_system_ended) {
                navigate({
                    to: '/reports/shift-history/shift-summary/$shiftId',
                    params: { shiftId: data.last_shift_id }
                });
            }
            setOpenShiftStart(!data.activeShiftFound)
            setDisplaySalesComp(data.activeShiftFound)
        }
    }, [data])

    useEffect(() => {
        if (value) {
            const getMed = async () => {
                if (value.type === "MEDICAL") {
                    try {
                        const item = await getMedicalItem(value.value)
                        await db.transaction('rw', db.sales_items, db.sales_items_batches, async (tx) => {
                            let item_discount = 0;
                            if (item[0].inventory_metadata.maximum_discount_type === "ALWAYS") {
                                item_discount = item[0].inventory_metadata.maximum_discount_value;
                            }
                            const check_if_added = await tx.sales_items.where('sale_id').equals(selected_sale?.id!).and(x => x.item_id === item[0].id).toArray();
                            if (check_if_added.length > 0) {
                                await tx.sales_items.update(check_if_added[0].id, { quantity_added: check_if_added[0].quantity_added + 1 });
                            } else {
                                await tx.sales_items.add({
                                    sale_id: selected_sale?.id!,
                                    item_id: item[0].id,
                                    unit: item[0].unit,
                                    unit_type: item[0].unit_type,
                                    barcode: item[0].barcode,
                                    trade_name: item[0].trade_name,
                                    quantity_added: 1,
                                    item_discount: item_discount,
                                    type: "MEDICAL"
                                })
                                for (let i = 0; i < item.length; i++) {
                                    await tx.sales_items_batches.add({
                                        sale_id: selected_sale?.id!,
                                        item_id: item[i].id,
                                        inventory_id: item[i].inventory_id,
                                        batch_number: item[i].batch_number,
                                        expiry_date: item[i].expiry_date,
                                        cost: item[i].cost,
                                        price: item[i].price,
                                        available_stock: item[i].available_stock,
                                        sold_quantity: item[i].sold_quantity,
                                        price_history_id: item[i].medical_pharmacy_price_history_id,
                                        vat: 0,
                                        type: "MEDICAL",
                                        is_selected: i === 0,
                                        max_discount_type: item[i].inventory_metadata.maximum_discount_type,
                                        max_discount_amount: item[i].inventory_metadata.maximum_discount_value,
                                        offers: JSON.stringify(item[i].offers)
                                    })
                                }
                            }
                        });
                    } catch (e) {
                        console.error(e)
                    }

                } else {
                    try {
                        const item = await getNonMedicalItem(value.value)
                        await db.transaction('rw', db.sales_items, db.sales_items_batches, async (tx) => {
                            let item_discount = 0;
                            if (item[0].inventory_metadata.maximum_discount_type === "ALWAYS") {
                                item_discount = item[0].inventory_metadata.maximum_discount_value;
                            }
                            const check_if_added = await tx.sales_items.where('sale_id').equals(selected_sale?.id!).and(x => x.item_id === item[0].non_medical_master_id).toArray();
                            if (check_if_added.length > 0) {
                                await tx.sales_items.update(check_if_added[0].id, { quantity_added: check_if_added[0].quantity_added + 1 });
                            } else {
                                await tx.sales_items.add({
                                    sale_id: selected_sale?.id!,
                                    item_id: item[0].non_medical_master_id,
                                    unit: item[0].unit,
                                    unit_type: item[0].unit_type,
                                    barcode: item[0].barcode,
                                    trade_name: item[0].trade_name,
                                    quantity_added: 1,
                                    item_discount: item_discount,
                                    type: "NON_MEDICAL"
                                })
                                for (let i = 0; i < item.length; i++) {
                                    await tx.sales_items_batches.add({
                                        sale_id: selected_sale?.id!,
                                        item_id: item[i].non_medical_master_id,
                                        inventory_id: item[i].non_medical_inventory_id,
                                        batch_number: item[i].batch_number,
                                        expiry_date: item[i].expiry_date.Valid ? item[i].expiry_date.Time : undefined,
                                        cost: item[i].cost,
                                        price: item[i].price,
                                        available_stock: item[i].available_stock,
                                        sold_quantity: item[i].sold_quantity,
                                        price_history_id: item[i].non_medical_pharmacy_price_history_id,
                                        vat: item[i].vat,
                                        type: "NON_MEDICAL",
                                        is_selected: i === 0,
                                        max_discount_type: item[i].inventory_metadata.maximum_discount_type,
                                        max_discount_amount: item[i].inventory_metadata.maximum_discount_value,
                                        offers: JSON.stringify(item[i].offers)
                                    })
                                }
                            }
                        });
                    } catch (e) {
                        console.log("Error2:", e)
                        console.error(e)
                    }
                }
                setSearch('')
                setResults([])
            }
            getMed()
        }
    }, [value])

    return (
        <>
            <StartShiftModal
                value={openShiftStart}
                setValue={setOpenShiftStart}
                setDisplaySalesComp={setDisplaySalesComp}
                showTriggerButton={false}
                page='sales'
            />
            {
                !displaySalesComp
                    ?
                    <NoShiftFound
                        value={openShiftStart}
                        setValue={setOpenShiftStart}
                        setDisplaySalesComp={setDisplaySalesComp}
                    />
                    :
                    <div
                        className='h-full flex'
                    >
                        <div className='flex flex-col w-full'>
                            <div className={``}>
                                <div className='flex items-center justify-between border-b-[0.5px] pb-4 mb-4'>
                                    <div className='flex items-center justify-center space-x-6'>
                                        {active_sales?.map((s, i) => (
                                            <div
                                                key={i}
                                                onClick={async () => {
                                                    try {
                                                        await db.active_sales.bulkUpdate(active_sales.map(a => {
                                                            return {
                                                                key: a.id,
                                                                changes: {
                                                                    selected: a.id === s.id
                                                                }
                                                            }
                                                        }))
                                                    } catch (e) {
                                                        console.error(e)
                                                    }
                                                }}
                                            >
                                                <ContextMenu>
                                                    <ContextMenuTrigger>
                                                        <p className={`text-sm font-semibold cursor-pointer ${selected_sale?.id === s.id ? "bg-gray-100" : ""
                                                            } px-3 py-2 rounded-lg`}>Sale {i + 1}</p>
                                                    </ContextMenuTrigger>
                                                    <ContextMenuContent>
                                                        <ContextMenuItem onClick={async () => {
                                                            try {
                                                                await db.active_sales.bulkUpdate(active_sales.map(a => {
                                                                    return {
                                                                        key: a.id,
                                                                        changes: {
                                                                            selected: a.id === s.id
                                                                        }
                                                                    }
                                                                }))
                                                            } catch (e) {
                                                                console.error(e)
                                                            }
                                                        }}>Go to Sale</ContextMenuItem>
                                                        <ContextMenuItem onClick={async () => {
                                                            try {
                                                                await db.transaction('rw', db.active_sales, db.sales_items, db.sales_items_batches, async (tx) => {
                                                                    const to_beDeleted_sale = await tx.active_sales.where('id').equals(s.id).toArray();
                                                                    const to_beDeletedSaleItems = await tx.sales_items.where('sale_id').equals(s.id).toArray();
                                                                    const to_beDeletedSaleItemsBatches = await tx.sales_items_batches.where('sale_id').equals(s.id).toArray();
                                                                    await tx.active_sales.bulkDelete(to_beDeleted_sale.map(a => a.id));
                                                                    await tx.sales_items.bulkDelete(to_beDeletedSaleItems.map(a => a.id));
                                                                    await tx.sales_items_batches.bulkDelete(to_beDeletedSaleItemsBatches.map(a => a.id));
                                                                    active_sales && await tx.active_sales.update(active_sales[0].id, { selected: true });
                                                                })
                                                            } catch (e) {
                                                                console.error(e)
                                                            }
                                                        }} disabled={
                                                            active_sales?.length === 1
                                                        }>Discard Sale</ContextMenuItem>
                                                    </ContextMenuContent>
                                                </ContextMenu>
                                            </div>
                                        ))}
                                        <Button
                                            variant="outline"
                                            className='text-sm'
                                            onClick={addNewMultiSale}
                                        >
                                            <p>+ Add Sale</p>
                                        </Button>
                                    </div>
                                    {/* <div className="flex items-center justify-between border rounded-md space-x-4 px-4 py-3 min-w-[200px]">
                                        <p className='text-sm font-semibold'>Insurance Sale</p>
                                        <Switch 
                                            className='w-10 h-4'
                                            checked={switchInsurance}
                                            onCheckedChange={() => {setSwitchInsurance(!switchInsurance)}}
                                        />
                                    </div> */}
                                </div>

                                {active_sales?.map(a => a.id).includes(selected_sale?.id!) ? <div className='flex items-center justify-center space-x-12 mb-8'>
                                    <p className='text-3xl font-semibold'>Product list</p>
                                    <div className='flex-1 mx-4 relative'>
                                        <div className='border rounded-md px-2 flex items-center'>
                                            <AutoComplete
                                                options={results}
                                                emptyMessage="No results."
                                                placeholder="Search for a product"
                                                onValueChange={setValue}
                                                value={value}
                                                input={search}
                                                onInputChanged={setSearch}
                                                shouldFilter={false}
                                                ref={autoCompleteRef}
                                            />
                                            <Button
                                                className='px-2 py-0 h-8'
                                                variant={'outline'}
                                                size={'sm'}
                                                onClick={() => {
                                                    setSearch('');
                                                }}
                                            >
                                                Clear
                                            </Button>
                                        </div>
                                    </div>

                                    <Button
                                        onClick={() => {
                                            setSelected([])
                                            try {
                                                db.transaction('rw', db.sales_items, db.sales_items_batches, async (tx) => {
                                                    const to_beDeletedSaleItems = await tx.sales_items.where('sale_id').equals(selected_sale?.id!).toArray();
                                                    const to_beDeletedSaleItemsBatches = await tx.sales_items_batches.where('sale_id').equals(selected_sale?.id!).toArray();
                                                    await tx.sales_items.bulkDelete(to_beDeletedSaleItems.map(a => a.id));
                                                    await tx.sales_items_batches.bulkDelete(to_beDeletedSaleItemsBatches.map(a => a.id));
                                                })
                                            } catch (e) {
                                                console.error(e)
                                            }
                                        }}
                                    >
                                        Remove All
                                    </Button>
                                </div> : <NoSaleSelected />}
                            </div>
                            {active_sales?.map(a => a.id).includes(selected_sale?.id!) && <div className='flex-1 overflow-auto pb-4'>
                                <div className={`h-full relative flex flex-col`}>
                                    <Table className='mb-24'>
                                        <TableHeader>
                                            <TableRow>
                                                <TableHead>Product Name</TableHead>
                                                <TableHead>Batch No. | Barcode Number | Expiry Date</TableHead>
                                                <TableHead>Available Stock</TableHead>
                                                <TableHead>Unit Type</TableHead>
                                                <TableHead>Quantity</TableHead>
                                                <TableHead>Discount</TableHead>
                                                <TableHead>Total</TableHead>
                                                <TableHead></TableHead>
                                            </TableRow>
                                        </TableHeader>
                                        <TableBody>
                                            {
                                                selected_items?.map && selected_items.filter(a => a.sale_id === selected_sale?.id).map((item, index: number) => {
                                                    const price = selected_items_batches?.find((batch) => batch.item_id === item.item_id && batch.is_selected && batch.sale_id === item.sale_id)?.price!;
                                                    const vat = selected_items_batches?.find((batch) => batch.item_id === item.item_id && batch.is_selected && batch.sale_id === item.sale_id)?.vat!;
                                                    const available_stock = selected_items_batches?.find((batch) => batch.item_id === item.item_id && batch.is_selected && batch.sale_id === item.sale_id)?.available_stock!;
                                                    const sold_quantity = selected_items_batches?.find((batch) => batch.item_id === item.item_id && batch.is_selected && batch.sale_id === item.sale_id)?.sold_quantity!;
                                                    const inventory_id = selected_items_batches?.find((batch) => batch.item_id === item.item_id && batch.is_selected && batch.sale_id === item.sale_id)?.inventory_id;
                                                    const discount_type = selected_items_batches?.find((batch) => batch.item_id === item.item_id && batch.is_selected && batch.sale_id === item.sale_id)?.max_discount_type;
                                                    const offers = selected_items_batches?.find((batch) => batch.item_id === item.item_id && batch.is_selected && batch.sale_id === item.sale_id)?.offers ? JSON.parse(selected_items_batches?.find((batch) => batch.item_id === item.item_id && batch.is_selected && batch.sale_id === item.sale_id)?.offers!) as Offer[] : [] as Offer[];
                                                    return (
                                                        <TableRow
                                                            key={index}
                                                            className={index === selectedItemIndex ? 'bg-gray-100' : ''}>
                                                            <TableCell>{item.trade_name}</TableCell>
                                                            <TableCell>
                                                                <Select
                                                                    value={inventory_id}
                                                                    onValueChange={(value) => {
                                                                        try {
                                                                            db.transaction('rw', db.sales_items, db.sales_items_batches, async (tx) => {
                                                                                await tx.sales_items_batches.where('sale_id').equals(selected_sale?.id!).and(item => item.item_id === item.item_id).modify({ is_selected: false });
                                                                                await tx.sales_items_batches.where('sale_id').equals(selected_sale?.id!).and(item => item.item_id === item.item_id).and(item => item.inventory_id === value).modify({ is_selected: true });
                                                                                await tx.sales_items.where('sale_id').equals(selected_sale?.id!).and(item => item.item_id === item.item_id).modify({ quantity_added: 1 });
                                                                                const discount_type_target = selected_items_batches?.find((batch) => batch.inventory_id === value && batch.item_id === item.item_id && batch.sale_id === item.sale_id)?.max_discount_type;
                                                                                if (discount_type_target === "ALWAYS") {
                                                                                    await tx.sales_items.where('sale_id').equals(selected_sale?.id!).and(item => item.item_id === item.item_id).modify({ item_discount: selected_items_batches?.find((batch) => batch.inventory_id === value && batch.item_id === item.item_id && batch.sale_id === item.sale_id)?.max_discount_amount });
                                                                                } else {
                                                                                    await tx.sales_items.where('sale_id').equals(selected_sale?.id!).and(item => item.item_id === item.item_id).modify({ item_discount: 0 });
                                                                                }
                                                                            })
                                                                        } catch (e) {
                                                                            console.error(e)
                                                                        }
                                                                    }}
                                                                >
                                                                    <SelectTrigger className="w-[280px]">
                                                                        <SelectValue placeholder="Select a batch" />
                                                                    </SelectTrigger>
                                                                    <SelectContent>
                                                                        <SelectGroup>
                                                                            {
                                                                                selected_items_batches?.filter(a => {
                                                                                    return a.item_id === item.item_id && a.sale_id === selected_sale?.id;
                                                                                }).map((batch, index) => (
                                                                                    <SelectItem key={index} value={batch.inventory_id} disabled={batch.available_stock <= 0}>
                                                                                        {batch.batch_number} {item.barcode ? "| " + item.barcode : ""} | {dayjs(batch.expiry_date).isValid() ? dayjs(batch.expiry_date).format('DD/MM/YYYY') : "N/A"}
                                                                                    </SelectItem>
                                                                                ))
                                                                            }
                                                                        </SelectGroup>
                                                                    </SelectContent>
                                                                </Select>

                                                            </TableCell>
                                                            <TableCell>{available_stock}</TableCell>
                                                            <TableCell>{item.unit_type}</TableCell>
                                                            <TableCell className="flex items-center gap-2">
                                                                <QuantityModal
                                                                    item={item}
                                                                    available_stock={selected_items_batches?.find((batch) => batch.item_id === item.item_id && batch.is_selected && batch.sale_id === item.sale_id)?.available_stock!}
                                                                    selected_sale={selected_sale?.id!}
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <DiscountModal
                                                                    item_discount={item.item_discount}
                                                                    trade_name={item.trade_name}
                                                                    allowed_to_open={discount_type !== "NONE" || offers?.length! > 0}
                                                                    discount_type={discount_type!}
                                                                    max_discount_value={selected_items_batches?.find((batch) => batch.item_id === item.item_id && batch.is_selected && batch.sale_id === item.sale_id)?.max_discount_amount!}
                                                                    price={price}
                                                                    sale_id={selected_sale?.id!}
                                                                    item_id={item.item_id}
                                                                    quantity={item.quantity_added}
                                                                    offers={offers || []}
                                                                />
                                                            </TableCell>
                                                            {/* <TableCell>{convertToCurrencyRounded(parseFloat(item.item_discount!))}</TableCell> */}
                                                            <TableCell>
                                                                {
                                                                    convertToCurrencyRounded(getItemCost({
                                                                        price: price!,
                                                                        quantity: item.quantity_added!,
                                                                        vat: vat!,
                                                                        discount: item.item_discount!,
                                                                        discount_type: discount_type!
                                                                    }))
                                                                }
                                                            </TableCell>
                                                            {/* <TableCell>{convertToCurrencyRounded(((price! * parseFloat(item.quantity_added!)) + ((price! * parseFloat(item.quantity_added!)) * vat! / 100)) - parseFloat(item.item_discount))}</TableCell> */}
                                                            <TableCell><DeleteIcon onClick={async () => {
                                                                try {
                                                                    await db.transaction('rw', db.sales_items, db.sales_items_batches, async (tx) => {
                                                                        const to_beDeletedSaleItems = await tx.sales_items.where('sale_id').equals(selected_sale?.id!).and(x => x.item_id === item.item_id).toArray();
                                                                        const to_beDeletedSaleItemsBatches = await tx.sales_items_batches.where('sale_id').equals(selected_sale?.id!).and(x => x.item_id === item.item_id).toArray();
                                                                        await tx.sales_items.bulkDelete(to_beDeletedSaleItems.map(a => a.id));
                                                                        await tx.sales_items_batches.bulkDelete(to_beDeletedSaleItemsBatches.map(a => a.id));
                                                                    })
                                                                } catch (e) {
                                                                    console.error(e)
                                                                }
                                                            }} style={{
                                                                cursor: 'pointer'
                                                            }} /></TableCell>
                                                        </TableRow>
                                                    )
                                                }
                                                )}
                                        </TableBody>
                                    </Table>
                                    <div className='w-full absolute bottom-0'>
                                        <Footer
                                            internalSalesId={selected_sale?.id!}
                                            total={
                                                netTotal
                                            }
                                            discount={
                                                totaldiscount
                                            }
                                            vat={
                                                totalvat
                                            }
                                            checkoutButtonRef={checkoutButtonRef}
                                        />
                                    </div>
                                </div>
                            </div>}
                        </div>
                        {
                            switchInsurance ? (
                                <div className='bg-[#F9FAFB] flex-1 px-4 mx-2 min-w-[400px] relative'>
                                    <div className="grid w-full max-w-sm items-center gap-1.5 mt-4">
                                        <Label
                                            htmlFor="emirates"
                                            aria-required="true"
                                        >Emirates ID <span className='text-red-500'>*</span></Label>
                                        <Input
                                            type="text"
                                            id="emirates"
                                            placeholder="***-****-*******-*"
                                            value={emiratesID}
                                            onChange={(event) => {
                                                let newValue = event.target.value.replace(/\D/g, ''); // Remove non-digits

                                                // Insert dashes at the correct positions
                                                if (newValue.length > 3) {
                                                    newValue = newValue.slice(0, 3) + '-' + newValue.slice(3);
                                                }
                                                if (newValue.length > 8) {
                                                    newValue = newValue.slice(0, 8) + '-' + newValue.slice(8);
                                                }
                                                if (newValue.length > 16) {
                                                    newValue = newValue.slice(0, 16) + '-' + newValue.slice(16);
                                                }
                                                setEmiratesID(newValue);
                                            }}
                                            maxLength={18}
                                            required
                                        />
                                    </div>

                                    <div className="grid w-full max-w-sm items-center gap-1.5 mt-4">
                                        <Label
                                            htmlFor="erx"
                                            aria-required="true"
                                        >ERX <span className='text-red-500'>*</span></Label>
                                        <Input
                                            type="text"
                                            id="erx"
                                            placeholder="Need to check"
                                            // value={erx}
                                            // onChange={(event) => {
                                            //     let newValue = event.target.value.replace(/\D/g, ''); // Remove non-digits

                                            //     // Insert dashes at the correct positions
                                            //     if (newValue.length > 3) {
                                            //     newValue = newValue.slice(0, 3) + '-' + newValue.slice(3);
                                            //     }
                                            //     if (newValue.length > 8) {
                                            //     newValue = newValue.slice(0, 8) + '-' + newValue.slice(8);
                                            //     }
                                            //     if (newValue.length > 16) {
                                            //     newValue = newValue.slice(0, 16) + '-' + newValue.slice(16);
                                            //     }
                                            //     setERX(newValue);
                                            // }}
                                            required
                                        />
                                    </div>

                                    <hr
                                        className='my-4'
                                    />

                                    <Button
                                        className='w-full'
                                    >
                                        <CheckCircle
                                            className='mr-2 h-4 w-4'
                                        /> Submit & Retrieve items
                                    </Button>

                                    <Button
                                        className='w-full mt-4 text-gray-400'
                                        variant={'outline'}
                                    >
                                        <CheckCircle
                                            className='mr-2 h-4 w-4 text-gray-400'
                                        /> Print Prescription
                                    </Button>

                                    <div
                                        className='absolute bottom-2 bg-[#EF4444] p-4 rounded-lg'
                                    >
                                        <p className='text-[#FAFAFA] font-semibold'>ERX not found!</p>
                                        <p className='text-[#FAFAFA] text-sm'>Please check the prescription and try again.</p>
                                        <Button
                                            variant={'outline'}
                                            className='text-white bg-[#EF4444] mt-4'
                                        >Try Again</Button>
                                    </div>
                                </div>
                            ) : null
                        }
                    </div>
            }
        </>
    )
}

export default component